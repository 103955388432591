import globalLogger from "@/logging";
import type {PGActionTree, RootState} from "@/store/index-types";
import {actionRequestWrap} from "@/store/index-types";
import {autoMutations} from "@/store/utils";
import type {Answers} from "pg-isomorphic/api/answers";
import type {ConnectionWithNames} from "pg-isomorphic/api/connection";
import type {AdminEntityInfo, IdentityScore} from "pg-isomorphic/api/entity";
import type {CalendarJob} from "pg-isomorphic/api/queue/calendar";
import type {IndexResult} from "pg-isomorphic/api/queue/indexer";
import type {DeadLetter, DeadLetterQueryOptions, QueueStats} from "pg-isomorphic/api/queue/messages";
import type {Task} from "pg-isomorphic/api/tasks";
import type {ListUser} from "pg-isomorphic/api/user";
import type {Commit, GetterTree} from "vuex";
import type {EnvironmentExportMessage, EnvironmentExportStatus} from "pg-isomorphic/api/export";
import {RoutingKey} from "pg-isomorphic/queue";
import type {Stage} from "pg-isomorphic/enums";
import {TaskState} from "pg-isomorphic/enums";
import type {Role} from "pg-isomorphic/api/role";
import {getToast} from "@/composables/toast";
import type {CustomTemplates} from "@/composables/admin/super/entities";

const logger = globalLogger.getLogger("adminEntities");

const tabs = [
  "entity",
  "queue",
  "search",
  "config",
  "bulk_delete_connections",
  "bulk_update_tin_checks",
  "master_data",
];
const queueTabs = ["stats", "deadletters"];
const entityTabs = [
  "entity",
  "connections",
  "export",
  "users",
  "tasks",
  "events",
  "email-templates",
  "features",
  "settings",
  "apikeys",
  "datawarehouse",
  "documents",
  "kits",
];

export interface EntityAdminState {
  entities: AdminEntityInfo[];
  connections: ConnectionWithNames[];
  connectionsTotal: number;
  entity: AdminEntityInfo | null;
  otherCounterpartyConnectionAnswers: Answers | null;
  entityUsers: ListUser[];
  entityUsersTotal: number;
  connection: ConnectionWithNames | null;
  entityAnswers: Answers | null;
  updatedTrustScore: IdentityScore | null;
  entityTasks: Task[];
  scheduledEvents: Task[];
  nonConnectionEntityTasks: Task[];
  counterpartyTasks: Task[];
  counterpartyAnswers: Answers | null;
  entityConnectionAnswers: Answers | null;
  counterpartyConnectionAnswers: Answers | null;
  entitySearch: string;
  connectionSearch: string;
  error: Error | null;
  loading: boolean;
  usersLoading: boolean;
  queueStats: QueueStats[];
  exportIds: string[];
  deadLetters: DeadLetter[];
  deadLettersSelected: DeadLetter[];
  deadLetterQueue: string | null;
  activeTab: string;
  entityTab: string;
  queueTab: string;
  deadLetterQuery: DeadLetterQueryOptions;
  connectionSearchIncludeDeleted: boolean;
  entitySearchIncludeDeleted: boolean;
  environmentExport: EnvironmentExportMessage | null;
  environmentExportOpen: boolean;
  environmentExportStatus: EnvironmentExportStatus | null;
  entityRoles: Role[];
  reassigningTask: Task | null;
  reassignTaskOpen: boolean;
  templates: CustomTemplates;
  rerenderAnswers: number;
  taskProcessingJobId: string | null;
  taskProcessingStatus: string | null;
  taskProcessingResults: any;
  showTaskProcessingResults: boolean;
}

const initialState: EntityAdminState = {
  entities: [],
  connections: [],
  connectionsTotal: 0,
  entityTasks: [],
  scheduledEvents: [],
  nonConnectionEntityTasks: [],
  counterpartyTasks: [],
  entity: null,
  entityUsers: [],
  entityUsersTotal: 0,
  connection: null,
  entityAnswers: null,
  updatedTrustScore: null,
  counterpartyAnswers: null,
  entityConnectionAnswers: null,
  counterpartyConnectionAnswers: null,
  entitySearch: "",
  connectionSearch: "",
  otherCounterpartyConnectionAnswers: null,
  error: null,
  loading: false,
  usersLoading: false,
  queueStats: [],
  exportIds: [],
  deadLetters: [],
  deadLettersSelected: [],
  deadLetterQueue: null,
  activeTab: tabs[0],
  entityTab: entityTabs[0],
  queueTab: queueTabs[0],
  deadLetterQuery: {
    queue: "",
    entity: "",
    includeRequeued: false,
    page: 0,
    limit: 1000,
  },
  connectionSearchIncludeDeleted: false,
  entitySearchIncludeDeleted: false,
  environmentExport: null,
  environmentExportOpen: false,
  environmentExportStatus: null,
  entityRoles: [],
  reassigningTask: null,
  reassignTaskOpen: false,
  templates: {},
  rerenderAnswers: 0,
  taskProcessingJobId: null,
  taskProcessingStatus: null,
  taskProcessingResults: null,
  showTaskProcessingResults: false,
};

function req<T>(fn: () => Promise<T>, commit: Commit, errorMessage?: string): Promise<T | undefined> {
  return actionRequestWrap<T>(logger, "error loading adminEntities API")(fn, commit, errorMessage);
}

let jobMessage: (x: any) => void = () => {
  /**/
};

const actions: PGActionTree<EntityAdminState> = {
  async searchEntities({commit, dispatch, state}, searchEverything) {
    return req(
      async () => {
        const response = await this.httpGet<{entities: AdminEntityInfo[]; templates: CustomTemplates}>(
          `/api/admin/super/entities?search=${encodeURIComponent(state.entitySearch)}&includeDeleted=${
            state.entitySearchIncludeDeleted
          }&searchEverything=${searchEverything || ""}`,
        );
        await dispatch("reset");
        commit("entities", response.data.entities);
        commit("templates", response.data.templates);
      },
      commit,
      "error searching entities",
    );
  },

  reset({commit}) {
    for (const k of Object.keys(initialState)) {
      if (k === "entitySearch" || k === "activeTab" || k === "entitySearchIncludeDeleted") {
        continue;
      }
      // @ts-ignore - allow index access for easy reset
      commit(k, initialState[k]);
    }
  },

  async selectEntity({commit}, entity: AdminEntityInfo) {
    commit("entities", []);
    commit("entitySearch", "");
    commit("entity", entity);
  },

  async searchConnections({commit, state}, topConnectionsLimit) {
    if (!state.entity) {
      commit("error", {customError: "No entity selected"});
      return;
    }
    let url = `/api/admin/super/entities/${state.entity!._id}/connections?search=${state.connectionSearch}`;
    if (topConnectionsLimit) {
      url = `${url}&limit=${topConnectionsLimit}`;
    }
    if (state.connectionSearchIncludeDeleted) {
      url += `&includeDeleted=true`;
    }
    return req(
      async () => {
        const response = await this.httpGet<{total: number; connections: ConnectionWithNames[]}>(url);
        (response.data.connections || []).forEach((conn) => {
          if (conn.requestingEntity === state.entity._id) {
            conn.counterpartyEntityInfo = conn.respondingEntityInfo;
          } else {
            conn.counterpartyEntityInfo = conn.requestingEntityInfo;
          }
        });
        commit("connections", response.data.connections);
        commit("connectionsTotal", response.data.total);
      },
      commit,
      "error searching connections",
    );
  },
  async refreshConnection({dispatch, commit, state}) {
    if (!state.entity || !state.connection) {
      return;
    }
    const entityId = state.entity._id;
    const connectionId = state.connection._id;
    const tab = state.entityTab;
    commit("entitySearch", entityId);
    await dispatch("searchEntities");
    await dispatch("selectEntity", state.entities[0]);
    commit("connectionSearch", connectionId);
    await dispatch("searchConnections");
    await dispatch("selectConnection", state.connections[0]);
    commit("entityTab", tab);
  },
  async selectConnection({commit, dispatch}, connection: ConnectionWithNames) {
    commit("connections", []);
    commit("connectionSearch", "");
    commit("connection", connection);
    if (connection) {
      await dispatch("getConnectionDetail");
    }
  },

  async searchKits({commit, state}) {
    return req(async () => {
      const response = await this.httpGet<{entities: AdminEntityInfo[]}>(
        `/api/admin/super/entities?search=${encodeURIComponent(state.entitySearch)}`,
      );
    }, commit);
  },

  async calcTrustScore({commit, state}) {
    return req(async () => {
      const response = await this.httpPost(`/api/admin/tools/identity_score/update`, {entity: state.entity!._id});
      if (response.status === 200) {
        commit("updatedTrustScore", response.data);
      }
    }, commit);
  },

  async getEntityAnswers({commit, state}) {
    return req(
      async () => {
        const response = await this.httpGet<{answers: Answers}>(
          `/api/admin/super/entities/${state.entity!._id}/answers`,
        );
        commit("entityAnswers", response.data.answers);
        commit("rerenderAnswers", ++state.rerenderAnswers);
      },
      commit,
      `error getting ${state.entity!.name} answers`,
    );
  },
  async getEntitiesTasks({state, commit}) {
    return req(
      async () => {
        const response = await this.httpGet<{tasks: Task[]}>(`/api/admin/super/entities/${state.entity!._id}/tasks`);
        commit("nonConnectionEntityTasks", response.data.tasks);
      },
      commit,
      "error getting entity tasks",
    );
  },
  async getEntitiesEvents({state, commit}) {
    return req(
      async () => {
        const response = await this.httpGet<{events: CalendarJob[]}>(
          `/api/admin/super/entities/${state.entity!._id}/events`,
        );
        logger.trace(() => `Got ${response.data.events.length} events for ${state.entity!.name}`, response.data.events);
        commit("scheduledEvents", response.data.events);
      },
      commit,
      "error getting calendar events",
    );
  },
  async getConnectionDetail({commit, state, getters: getter}) {
    if (!state.entity) {
      commit("error", {customError: "No entity selected"});
      return;
    }
    if (!state.connection) {
      commit("error", {customError: "No connection selected"});
      return;
    }

    const counterpartyId = getter.counterpartyId as string;

    const a2 = req(
      async () => {
        const response = await this.httpGet<{answers: Answers}>(`/api/admin/super/entities/${counterpartyId}/answers`);
        commit("counterpartyAnswers", response.data.answers);
        commit("rerenderAnswers", ++state.rerenderAnswers);
      },
      commit,
      `error getting counterparty answers`,
    );

    const a3 = req(
      async () => {
        const response = await this.httpGet<{answers: any[]}>(
          `/api/admin/super/entities/${getter.counterpartyId}/connections/${state.connection!._id}/answers`,
        );
        const counterpartyConnectionAnswers =
          response.data.answers.find((a) => a.entityId?._id === counterpartyId) || {};
        const entityConnectionAnswers = response.data.answers.find((a) => a.entityId?._id === state.entity!._id) || {};
        commit("counterpartyConnectionAnswers", counterpartyConnectionAnswers);
        commit("entityConnectionAnswers", entityConnectionAnswers);
        commit("rerenderAnswers", ++state.rerenderAnswers);
      },
      commit,
      `error getting connection answers`,
    );

    const a4 = req(
      async () => {
        const response = await this.httpGet<{tasks: Task[]}>(
          `/api/admin/super/entities/${state.entity!._id}/connections/${state.connection!._id}/tasks`,
        );
        commit("entityTasks", response.data.tasks);
      },
      commit,
      `error getting entity connection tasks`,
    );

    const a5 = req(
      async () => {
        const response = await this.httpGet<{tasks: Task[]}>(
          `/api/admin/super/entities/${counterpartyId}/connections/${state.connection!._id}/tasks`,
        );
        commit("counterpartyTasks", response.data.tasks);
      },
      commit,
      `error getting counterparty connection tasks`,
    );

    return Promise.all([a2, a3, a4, a5]);
  },

  async grantAccess({commit, state}) {
    return req(
      async () => {
        const response = await this.httpPost<{user: any}>(`/api/admin/super/associate/${state.entity!._id}`, {});
        await commit("user", response.data.user, {root: true});
      },
      commit,
      `error giving user access to ${state.entity!.name}`,
    );
  },

  async revokeAccess({commit, state, rootState, dispatch}, entity) {
    return req(
      async () => {
        const entityId = entity || state.entity!._id;
        const response = await this.httpDelete<{user: any}>(`/api/admin/super/dissociate/${entityId}`, {});
        await commit("user", response.data.user, {root: true});
        if (entityId === rootState.user?.activeEntityId) {
          const nextEntity = (rootState.user?.entities || []).find((e) => e.entityId !== entityId);
          if (nextEntity) {
            await dispatch("userProfile/setActiveEntity", {
              entityId: nextEntity.entityId,
            });
          }
        }
      },
      commit,
      `error revoking user access to entity`,
    );
  },
  async getQueueStats({commit}) {
    return req(
      async () => {
        const response = await this.httpGet<{stats: QueueStats[]}>(`/api/admin/super/queues/stats`);
        await commit("queueStats", response.data.stats);
      },
      commit,
      `error getting stats`,
    );
  },

  async getDeadLetters({state, commit}) {
    return req(
      async () => {
        const response = await this.httpPost<{total: number; deadLetters: DeadLetter[]}>(
          `/api/admin/super/queues/deadletters/query`,
          state.deadLetterQuery,
        );
        await commit("deadLetters", response.data.deadLetters);
        await commit("deadLettersSelected", []);
      },
      commit,
      `error getting dead Letters`,
    );
  },

  async requeueDeadLetter({dispatch, commit, state}) {
    return req(async () => {
      await this.httpPut(`/api/admin/super/queues/deadletters/requeue`, {
        deadLetterIds: state.deadLettersSelected.map((d) => d._id),
      });
      await dispatch("getDeadLetters");
    }, commit);
  },

  async processTasks(
    {commit, state},
    {
      debug,
      dryRun,
      watchAnswerKeys,
      bothSides,
    }: {debug: boolean; dryRun: boolean; watchAnswerKeys: string[] | undefined; bothSides: boolean},
  ) {
    if (!state.entity) {
      commit("error", {customError: "No entity selected"});
      return;
    }
    if (!state.connection) {
      commit("error", {customError: "No connection selected"});
      return;
    }

    return req(
      async () => {
        commit("taskProcessingStatus", "Task processing requested");
        let entity: string;
        if (state.connection!.requestingEntity === state.entity!._id) {
          entity = state.connection!.respondingEntity;
        } else {
          entity = state.connection!.requestingEntity;
        }
        const res = this.httpPost<{answers: Answers[]}>(
          `/api/admin/super/entities/${entity}/connections/${state.connection!._id}/process-tasks`,
          {
            debug,
            jobId: state.taskProcessingJobId,
            dryRun,
            watchAnswerKeys,
            bothSides,
          },
        );
        return res;
      },
      commit,
      `error kicking off task processing`,
    );
  },

  joinTaskProcessingRooms({state, commit}) {
    jobMessage = (wsMessage) => {
      if (wsMessage.status) {
        commit("taskProcessingStatus", wsMessage.status);
      }
      if (wsMessage.results) {
        commit("taskProcessingResults", wsMessage.results);
        commit("showTaskProcessingResults", true);
      }
    };
    this.join(RoutingKey.jobProgress({jobId: state.taskProcessingJobId!}));
    this.socketOn(RoutingKey.jobProgress({jobId: state.taskProcessingJobId!}), jobMessage);
  },

  leaveTaskProcessingRooms({state, commit}) {
    this.leave(RoutingKey.jobProgress({jobId: state.taskProcessingJobId!}));
    this.socketOff(RoutingKey.jobProgress({jobId: state.taskProcessingJobId!}), jobMessage);
    commit("taskProcessingStatus", null);
    commit("taskProcessingResults", {errors: []});
    commit("taskProcessingJobId", null);
  },

  async breakLock({commit, state}) {
    if (!state.entity) {
      commit("error", {customError: "No entity selected"});
      return;
    }
    if (!state.connection) {
      commit("error", {customError: "No connection selected"});
      return;
    }

    return req(
      async () => {
        await this.httpPost<{answers: Answers[]}>(
          `/api/admin/super/entities/${state.entity!._id}/connections/${state.connection!._id}/break-lock`,
          {},
        );
      },
      commit,
      `error breaking connection lock`,
    );
  },
  async undoDeletedConnection({state, commit, dispatch}) {
    if (!state.connection) {
      commit("error", {customError: "No connection selected"});
      return;
    }
    if (!state.connection.deleted) {
      commit("error", {customError: "Connection not deleted"});
      return;
    }
    try {
      await this.httpPatch(`/api/admin/super/connection/restore`, {connectionId: state.connection._id});
      getToast().success("Connection Restored");
      dispatch("refresh");
    } catch (e) {
      commit("error", e.response.data.error);
    }
  },

  async removeTask({state, commit, dispatch}, task: Task) {
    return req(
      async () => {
        await this.httpDelete<{answers: Answers[]}>(`/api/admin/super/tasks/${task._id}`);
        if (state.connection) {
          await dispatch("getConnectionDetail");
        }
        await dispatch("getEntitiesTasks");
      },
      commit,
      `error removing task`,
    );
  },
  async undoCompletedTask({commit, dispatch}, task: Task) {
    return req(
      async () => {
        const response = await this.httpPut<{}>(`/api/admin/super/tasks/${task._id}/undo-complete`, {
          state: TaskState.IN_PROGRESS,
          completed: false,
        });
        dispatch("getConnectionDetail");
        return response.status;
      },
      commit,
      `error undoing completed task`,
    );
  },

  async processReviews({commit, state}) {
    if (!state.entity) {
      commit("error", {customError: "No entity selected"});
      return;
    }
    if (!state.connection) {
      commit("error", {customError: "No connection selected"});
      return;
    }

    return req(
      async () => {
        await this.httpPost<{answers: Answers[]}>(
          `/api/admin/super/entities/${state.entity!._id}/connections/${state.connection!._id}/process-reviews`,
          {},
        );
      },
      commit,
      `error kicking off review processing`,
    );
  },

  async exportEntity({state, commit, dispatch}) {
    if (!state.entity) {
      commit("error", {customError: "No entity selected"});
      return;
    }
    const publicIds = [state.entity!._id, ...state.exportIds];
    return req(
      async () => {
        const response = await this.httpPost<EnvironmentExportMessage>(`/api/admin/tools/environment/export`, {
          publicIds,
        });
        commit("environmentExport", response.data);
        commit("environmentExportOpen", true);
        dispatch("joinRooms");
      },
      commit,
      `error exporting entity`,
    );
  },

  joinRooms({state, commit}) {
    if (state.environmentExport) {
      jobMessage = (msg: EnvironmentExportStatus) => {
        if (!msg) {
          return;
        }
        commit("environmentExportStatus", msg);
      };
      this.join(RoutingKey.jobProgress({jobId: state.environmentExport!.jobId}));
      this.socketOn(RoutingKey.jobProgress({jobId: state.environmentExport!.jobId}), jobMessage);
    }
  },
  async leaveRooms({state}) {
    if (state.environmentExport?.jobId) {
      this.leave(RoutingKey.jobProgress({jobId: state.environmentExport!.jobId}));
      this.socketOff(RoutingKey.jobProgress({jobId: state.environmentExport!.jobId}), jobMessage);
    }
  },

  async exportConnection({state, commit, dispatch, getters: getter}) {
    if (!state.entity) {
      commit("error", {customError: "No entity selected"});
      return;
    }
    if (!state.connection) {
      commit("error", {customError: "No connection selected"});
      return;
    }
    const previousIds = [...state.exportIds];
    commit("exportIds", [getter.counterpartyId]);
    await dispatch("exportEntity");
    commit("exportIds", previousIds);
  },

  async refresh({dispatch}) {
    dispatch("refreshConnection");
  },

  async getEntityUsers({commit, state}, query) {
    if (!state.entity) {
      commit("error", {customError: "No entity selected"});
      return;
    }

    try {
      commit("usersLoading", true);

      const response = await this.httpGet<{total: number; users: ListUser[]}>(
        `/api/admin/super/entities/${state.entity!._id}/users`,
        query,
      );

      commit("entityUsers", response.data.users);
      commit("entityUsersTotal", response.data.total);
    } catch (e) {
      commit("error", {customError: e});
    } finally {
      commit("usersLoading", false);
    }
  },

  async indexSelectedEntity(
    {commit, state},
    params: {includeConnections: boolean; dropFirst?: boolean},
  ): Promise<IndexResult | undefined> {
    return req(
      async () => {
        const response = await this.httpPost<IndexResult>(`/api/admin/tools/search_index/update`, {
          entity: state.entity!._id,
          ...params,
        });
        return response.data;
      },
      commit,
      `error updated search index`,
    );
  },

  async refreshSelectedEntity({commit, dispatch, state}) {
    commit("entitySearch", state.entity._id);
    await dispatch("searchEntities");
    if (state.entities.length > 0) {
      await dispatch("selectEntity", state.entities[0]);
    }
  },

  async unblockEntityAck({commit, dispatch}, entityId: string) {
    return req(
      async () => {
        const response = await this.httpPatch<{
          canUnblock: boolean;
          count: number;
          pendingValidationCount: boolean;
        }>(`/api/admin/super/entities/${entityId}/unblock_acknowledgment`, {});
        const unblocked = response.data.canUnblock === true;
        const taskCount = response.data.count;
        const pendingValidationCount = response.data.pendingValidationCount;
        if (unblocked) {
          getToast().success("Entity Unblocked");
        } else if (taskCount && pendingValidationCount) {
          getToast().error(`${taskCount} validation tasks and validations pending in ${pendingValidationCount} topics`);
        } else if (taskCount) {
          getToast().error(`${taskCount} validation TASKS pending`);
        } else if (pendingValidationCount) {
          getToast().error(`Validations pending in ${pendingValidationCount} topics`);
        }
        dispatch("refreshSelectedEntity");
      },
      commit,
      "error unblocking entity",
    );
  },

  async runEntityStats({commit}, {entityId, type}) {
    return req(
      async () => {
        await this.httpPost<{}>(`/api/admin/super/entities/${entityId}/stats/${type || ""}`, {});
        getToast().success(`Running ${type} in the background -- this might take a while...`);
      },
      commit,
      "error running entity stats",
    );
  },

  async getStatsData({commit}, entityId) {
    return req(
      async () => {
        const response = await this.httpGet<{}>(`/api/entities/${entityId}/statsdata`, {});
        return response.data;
      },
      commit,
      "error getting entity stats",
    );
  },

  async indexSelectedConnection({commit, state}): Promise<IndexResult | undefined> {
    if (!state.connection) {
      commit("error", {customError: "No connection selected"});
      return;
    }
    return req(
      async () => {
        const response = await this.httpPost<IndexResult>(`/api/admin/tools/search_index/connection`, {
          entityId: state.entity!._id,
          connectionId: state.connection!._id,
        });
        return response.data;
      },
      commit,
      `error updated search index`,
    );
  },

  async rollback(
    {commit, state},
    {stage, skipTaskProcessing}: {stage: Stage; skipTaskProcessing?: boolean},
  ): Promise<void> {
    if (!state.connection) {
      commit("error", {customError: "No connection selected"});
      return;
    }
    if (stage === state.connection.status) {
      return;
    }
    return req(
      async () => {
        await this.httpPost(`/api/admin/super/rollback/${state.connection!._id}`, {
          stage,
          skipTaskProcessing,
        });
      },
      commit,
      `error rolling back connection`,
    );
  },
  async getTaskAudit({commit}, task) {
    return req(
      async () => {
        const response = await this.httpGet<{auditInfo: string[]}>(`/api/admin/super/tasks/${task._id}/audit`);
        return response.data.auditInfo;
      },
      commit,
      `error rolling back connection`,
    );
  },
  async getEntityRoles({commit}, {entityId}) {
    return req(
      async () => {
        const response = await this.httpGet<{roles: Role[]}>(`/api/admin/super/entities/${entityId}/roles`);
        commit("entityRoles", response.data.roles);
        return response.data.roles;
      },
      commit,
      `error getting entity roles`,
    );
  },
  async reassignTask({commit, state}, {task, actor, actorType}) {
    return req(
      async () => {
        const response = await this.httpPut<{}>(`/api/admin/super/tasks/${task._id}/reassign`, {
          actors: [{id: actor, type: actorType}],
          connectionId: state.connection?._id,
          entityId: task.entity,
        });
        return response.data;
      },
      commit,
      `error getting entity roles`,
    );
  },
  async changePublicId({commit, state}, gId) {
    if (!state.entity) {
      commit("error", {customError: "No entity selected"});
      return;
    }
    const data = {
      gId: gId,
      prevPublicId: state.entity.publicId,
      id: state.entity._id,
    };
    return req(
      async () => {
        const response = await this.httpPut(`/api/admin/super/updatePublicId`, data);
        return response.data;
      },
      commit,
      `error updating GraphiteId`,
    );
  },
  async cloneEntity({commit, state}, receviceData) {
    if (!state.entity) {
      commit("error", {customError: "Your not cloning a Entity"});
      return;
    }

    const data = {
      dba: receviceData.dba,
      entityName: receviceData.entityName,
      retainTaxId: receviceData.yn,
      entityId: state.entity._id,
      profileSelection: receviceData.profileSelection,
    };
    return req(
      async () => {
        const response = await this.httpPost(`/api/admin/super/cloneEntity`, data);
        return response.data;
      },
      commit,
      `error Cloning Entity`,
    );
  },
  async hideEntity({commit, state}) {
    if (!state.entity || !state.entity._id) {
      commit("error", {customError: "No entity selected"});
      return;
    }
    return req(
      async () => {
        await this.httpPut(`/api/admin/super/entityVisibility`, {
          entityId: state.entity._id,
          visibility: !state.entity.privateProfile,
        });
        return;
      },
      commit,
      `Error changing entity's visibility`,
    );
  },
  async deleteEntity({state, commit, dispatch}, deleteReason) {
    if (!state.entity || !state.entity._id) {
      commit("error", "No entity selected");
      return;
    }
    try {
      await this.httpDelete(`/api/admin/super/entities/${state.entity._id}`, {deleteReason});
      dispatch("selectEntity", null);
      dispatch("selectConnection", null);
    } catch (e) {
      commit("error", e.response.data.code);
    }
  },
  async unDeleteEntity({commit, state, dispatch}) {
    if (!state.entity || !state.entity._id) {
      commit("error", {customError: "No entity selected"});
      return;
    }
    try {
      await this.httpPatch(`/api/admin/super/entities/${state.entity._id}/undelete`, {});
      dispatch("selectEntity", null);
      dispatch("selectConnection", null);
    } catch (e) {
      commit("error", e.response.data.code);
    }
  },
  async sendUserInvite({commit, state}, updateUser) {
    if (!state.entity || !state.entity._id) {
      commit("error", {customError: "No entity selected"});
      return;
    }
    return req(
      async () => {
        const response = await this.httpPost(`/api/admin/super/invite_user`, {...updateUser, entity: state.entity._id});
        return response.status;
      },
      commit,
      `Error sending Invitation`,
    );
  },
};

const getters: GetterTree<EntityAdminState, RootState> = {
  counterpartyId(state: EntityAdminState): string | undefined {
    if (!state.entity || !state.connection) {
      return undefined;
    }
    return state.entity._id === state.connection.requestingEntity
      ? state.connection.respondingEntity
      : state.connection.requestingEntity;
  },
  entityTabs() {
    return entityTabs;
  },
  tabs() {
    return tabs;
  },
  queueTabs() {
    return queueTabs;
  },
};

export default {
  namespaced: true,
  state: {
    ...initialState,
  },
  mutations: {
    ...autoMutations(initialState),
  },
  actions,
  getters,
};
