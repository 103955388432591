<template>
  <RouterLink custom v-if="to" :to="to" v-slot="{navigate}">
    <Button
      :link="useLinkAttr"
      :severity="severity"
      :outlined="useOutlinedAttr"
      :disabled="disabled"
      :class="buttonClass"
      :plain="plain"
      :text="text"
      :size="sizing"
      @click="navigate"
      v-bind="$attrs"
    >
      <template v-if="icon">
        <slot name="icon">
          <font-awesome-icon :icon="icon" class="button-icon" />
        </slot>
      </template>

      <slot />
    </Button>
  </RouterLink>

  <a v-else-if="href" :target="$attrs.target" :href="href" class="link-button">
    <Button
      :link="useLinkAttr"
      :severity="severity"
      :outlined="useOutlinedAttr"
      :disabled="disabled"
      :class="buttonClass"
      :plain="plain"
      :text="text"
      :size="sizing"
      v-bind="$attrs"
    >
      <template v-if="icon">
        <slot name="icon">
          <font-awesome-icon :icon="icon" class="button-icon" />
        </slot>
      </template>

      <slot />
    </Button>
  </a>

  <Button
    v-else
    :link="useLinkAttr"
    :severity="severity"
    :outlined="useOutlinedAttr"
    :disabled="disabled"
    :class="buttonClass"
    :plain="plain"
    :text="text"
    :size="sizing"
    v-bind="$attrs"
  >
    <template v-if="icon">
      <slot name="icon">
        <font-awesome-icon :icon="icon" class="button-icon" />
      </slot>
    </template>

    <slot />
  </Button>
</template>

<script lang="ts" setup>
import type {GraphiteButtonProps} from "@/utils/graphite-button-types";
import Button from "primevue/button";
import type {ComputedRef} from "vue";
import {useSlots} from "vue";
import {computed, defineProps, ref, toRefs, watch} from "vue";

// PROPS
const props = withDefaults(defineProps<GraphiteButtonProps>(), {
  variant: null,
  disabled: false,
  size: null,
  href: null,
  to: null,
});

const {variant, size} = toRefs(props);

const slots = useSlots();

const severity = ref("");
const useLinkAttr = ref<boolean>(null);
const useOutlinedAttr = ref<boolean>(null);
const buttonClass = ref<string>(undefined);
const plain = ref(false);
const text = ref<boolean>(false);
const sizing = ref<"large" | "small" | undefined>();

watch(
  variant,
  () => {
    let variantToCheck = variant.value;
    if (!variantToCheck) {
      return;
    }
    if (variant.value.startsWith("outline-")) {
      useOutlinedAttr.value = true;
      variantToCheck = variantToCheck.substring(8);
    }

    plain.value = false;
    text.value = false;

    if (variant.value.startsWith("text-")) {
      text.value = true;
      variantToCheck = variantToCheck.substring(5);
    }

    switch (variantToCheck) {
      case "primary":
        severity.value = "primary";
        break;
      case "secondary":
        severity.value = "secondary";
        buttonClass.value = "p-button-outlined";
        break;
      case "success":
        severity.value = "success";
        break;
      case "danger":
      case "error":
        severity.value = "danger";
        break;
      case "warning":
      case "warn":
      case "required":
        severity.value = "warning";
        break;
      case "info":
        severity.value = "info";
        break;
      case "plain":
        severity.value = "info";
        plain.value = true;
        text.value = true;
        break;
      case "link":
        severity.value = null;
        useLinkAttr.value = true;
        break;
      case "tertiary":
        severity.value = "tertiary";
        buttonClass.value = "p-button-tertiary";
        break;
      case "light":
      case "dark":
        severity.value = null;
    }

    if (!slots.default) {
      buttonClass.value += " icon-only";
    }
  },
  {immediate: true},
);

watch(
  size,
  () => {
    let sizeToCheck = size.value;
    if (!sizeToCheck) {
      return;
    }
    switch (sizeToCheck) {
      case "xs":
      case "sm":
        sizing.value = "small";
        break;
      case "md":
        sizing.value = undefined;
        break;
      case "lg":
        sizing.value = "large";
        break;
    }
  },
  {immediate: true},
);

const sizeClass: ComputedRef<string> = computed(() => {
  if (!sizing.value) {
    return "";
  } else if (sizing.value === "small") {
    return "p-button-sm";
  } else if (sizing.value === "large") {
    return "p-button-lg";
  } else {
    return "";
  }
});
const severityClass: ComputedRef<string> = computed(() => {
  if (!severity.value) {
    return "";
  } else {
    return `p-button-${severity.value}`;
  }
});

const anchorClass = computed(() => {
  if (useLinkAttr.value) {
    return "p-button-link";
  }

  return "";
});

defineOptions({
  inheritAttrs: false,
});
</script>

<style lang="less" scoped>
@import "@{globals}";
button {
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.link-button {
  text-decoration: none !important;
  display: inline-block;
}

.icon-only {
  padding: 8px;
}
</style>
