<template>
  <template v-for="tooltip in tooltips" :key="tooltip.id">
    <GraphiteTooltip
      :disabled="!tooltip.content"
      :triggerElement="tooltip.trigger"
      :placement="tooltip.placement"
      :maxWidth="tooltip.maxWidth"
    >
      <template #tooltip>
        {{ tooltip.content }}
      </template>
    </GraphiteTooltip>
  </template>
</template>

<script lang="ts" setup>
import GraphiteTooltip from "@/components/common/GraphiteTooltip.vue";
import type {DynamicTooltip} from "@/components/common/GraphiteTooltipDirective";
import {tooltipEmitter, TooltipEvent} from "@/components/common/GraphiteTooltipDirective";
import {onMounted, onUnmounted, ref} from "vue";

const tooltips = ref<DynamicTooltip[]>([]);

onMounted(() => {
  tooltipEmitter.on(TooltipEvent.Create, (tooltip) => {
    tooltips.value.push(tooltip);
  });
  tooltipEmitter.on(TooltipEvent.Destroy, (tooltipId) => {
    tooltips.value = tooltips.value.filter((t) => t.id !== tooltipId);
  });
});

onUnmounted(() => {
  tooltipEmitter.off(TooltipEvent.Create);
  tooltipEmitter.off(TooltipEvent.Destroy);
});

defineOptions({
  compatConfig: {
    MODE: 3,
  },
});
</script>
