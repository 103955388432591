/**
 * CONNECTION / ENTITY PROFILE SPECIFIC CODE
 *
 * If you have code that works with a question tree then it's generally recommended you put it in QuestionTree.ts.
 *
 * Currently a lot of this code is interfacing with vuex, so not easily testable.
 */

import {getCounterProfile, getCurrentProfile} from "@/composables/connections/get-current.profile";
import type {
  AnswerSocketHandlers,
  ConnectionProfileData,
  ConnectionSideData,
  ConnectionSocketHandlers,
  MessagesElementCount,
  MessageSocketHandlers,
  NotesElementCount,
  PatchAnswersObject,
  QuestionApiParams,
  QuestionChangesSinceLastApiResponse,
  ReminderElementCount,
  SharedElementCount,
  TaskElementCount,
} from "@/composables/connections/types";
import {getEntity} from "@/composables/entities";
import {handleXhrError} from "@/composables/errorHandling";
import {RegisteredErrors} from "@/composables/errorHandling/registeredErrors";
import {getStore} from "@/composables/get.store";
import {translate} from "@/composables/i18n";
import {childrenAreReadonly} from "@/composables/questions/questionTree";
import type {QuestionTreeCounts, QuestionTreeSectionSpecificData} from "@/composables/questions/types";
import type {CustomConnectionRole} from "@/composables/search/types";
import {getToast} from "@/composables/toast";
import type {UserWithSets} from "@/composables/vuex";
import {getConnectionRole, getCurrentUser, getEntityInfo, getUserActiveEntityId} from "@/composables/vuex";
import {httpDelete, httpGet, httpPatch, httpPost, httpPut} from "@/composables/xhr";
import globalLogger from "@/logging";
import {ensureSingleQueryParam, getCurrentRoute} from "@/router/util";
import type {PGStore} from "@/store/index-types";
import {isExcel} from "@/utils/excel";
import type {AxiosResponse} from "axios";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import {pruneHydratedAnswerFields} from "pg-isomorphic/answers";
import {getFreeTextAnswer} from "pg-isomorphic/answers/values";
import type {ApprovalCount} from "pg-isomorphic/api/approval";
import type {
  Answers,
  ConnectionDataLite,
  ConnectionWithNames,
  QuestionApiResponse,
  QuestionsAnswers,
} from "pg-isomorphic/api/connection";
import type {ExtendedEntityInfo} from "pg-isomorphic/api/entity";
import type {MessageCount} from "pg-isomorphic/api/message";
import type {NoteCount} from "pg-isomorphic/api/note";
import type {ReminderCount} from "pg-isomorphic/api/reminder";
import type {ConnectionDocument} from "pg-isomorphic/api/search";
import type {Task, TaskCount} from "pg-isomorphic/api/tasks";
import type {UserAvatar} from "pg-isomorphic/api/user";
import {
  Address,
  Address as AddressKeys,
  Connection,
  ConnectionCancelAction,
  ConnectionRole,
  ConnectionSearchScope,
  Entity,
  Entity as Ent,
  getOppositeConnectionRole,
  GroupSubType,
  GroupType,
  Internal,
  oppositeProfile,
  Profile,
  QuestionType,
  Stage,
} from "pg-isomorphic/enums";
import {getConnectionRole as getConnectionRoleIso} from "pg-isomorphic/connections";
import type {SaveType} from "pg-isomorphic/enums/answers";
import {BooleanAnswers, MagicAnswerKeys, SpecialTabs} from "pg-isomorphic/enums/answers";
import {getOppositeConnectionRoleOrBoth} from "pg-isomorphic/enums/connections";
import type {KitType} from "pg-isomorphic/enums/element";
import {MessageObjectType} from "pg-isomorphic/enums/message";
import {ChangeType} from "pg-isomorphic/enums/queue";
import {canDisconnectConnection, userCanDeleteConnection} from "pg-isomorphic/permissions";
import {
  filter as filterQuestions,
  find,
  findNearestNeighbor,
  findParent,
  findParentOfType,
  findSubTopic,
  findTopic,
} from "pg-isomorphic/profile";
import type {MessagesByTypeParams} from "pg-isomorphic/queue";
import {RoutingKey} from "pg-isomorphic/queue";
import type {JSONQuestion} from "pg-isomorphic/utils";
import {sleep, stickyScroll, waitForSelector} from "pg-isomorphic/utils";
import {UrlUtility} from "pg-isomorphic/utils/url";
import {filter, findIndex, head, omit, path, pathOr, propEq} from "ramda";
import type {ComputedRef, Ref} from "vue";
import {computed, nextTick} from "vue";
import VueScrollTo from "vue-scrollto";

const logger = globalLogger.getLogger("composables.connection");

// Connection Data Getting Methods
export function getUserConnectionRole(user: UserWithSets, reqEntity: string, reqRole: string, resRole: string): string {
  return user.activeEntityId === reqEntity ? reqRole : resRole;
}

/**
 * @deprecated
 */
export function getConnectionRoleComputed(): ComputedRef<ConnectionRole> {
  return computed(() => getStore().getters["profile/connection/connectionRole"]);
}

export function getConnectionRoleFromConnectionData(
  activeEntityId: string,
  connectionData: ConnectionProfileData | undefined,
): ConnectionRole {
  return getConnectionRoleIso(connectionData?.connection, activeEntityId);
}

export function getConnectionRoleFromConnectionDataComputed(
  connectionData: Ref<ConnectionProfileData>,
): ComputedRef<ConnectionRole> {
  const user = getCurrentUser();
  return computed(() => getConnectionRoleFromConnectionData(user.activeEntityId, connectionData.value));
}

export function getEntityRole(entityId: string, connectionData: ConnectionProfileData) {
  const respondingEntity = connectionData.connection?.respondingEntity;
  return entityId === respondingEntity
    ? pathOr("", ["connection", "respondingRole"], connectionData)
    : pathOr("", ["connection", "requestingRole"], connectionData);
}

export function getUseStreamlinedConnectionProcessComputed(connectionData: Ref<ConnectionProfileData>) {
  return computed(() => {
    const respondingEntity = connectionData.value?.connection?.respondingEntity;
    const campaignRegistrations = pathOr([], ["connection", "campaignRegistrations"], connectionData.value);
    const user = getCurrentUser();
    return campaignRegistrations.length === 0 && user.activeEntityId === respondingEntity;
  });
}

export function getIsRegisteringForCampaigns(connectionData: ConnectionProfileData, entityId: string): boolean {
  if (!connectionData) {
    return null;
  }

  const isSellerOwnedCampaign =
    connectionData.connection.requestingRole === ConnectionRole.BUYER &&
    (connectionData.connection.buyerCampaignRegistrations?.length || 0) > 0;
  const isBuyerOwnedCampaign =
    connectionData.connection.requestingRole === ConnectionRole.SELLER &&
    (connectionData.connection.campaignRegistrations?.length || 0) > 0;
  const entityRole = getEntityRole(entityId, connectionData);
  if (
    (isSellerOwnedCampaign && entityRole === ConnectionRole.BUYER) ||
    (isBuyerOwnedCampaign && entityRole === ConnectionRole.SELLER)
  ) {
    return true;
  }
  return false;
}

/**
 * @deprecated
 */
export function getConnectionStatusComputed(): ComputedRef<Stage> {
  return computed(() => getStore().state.profile?.connection?.status);
}

export function getConnectionData(connectionData: ConnectionProfileData): ConnectionWithNames {
  return connectionData?.connection;
}

export function getEntityDataComputed(connectionData: Ref<ConnectionProfileData>, which: Profile) {
  return computed(() => getEntityData(connectionData.value, which));
}

export function getEntityData(connectionData: ConnectionProfileData, which: Profile): ExtendedEntityInfo {
  return connectionData?.[which]?.entity || null;
}

export function getCurrentEntityData(connectionData: ConnectionProfileData): ExtendedEntityInfo {
  return getEntityData(connectionData, getCurrentProfile());
}

export function getCounterEntityData(connectionData: ConnectionProfileData): ExtendedEntityInfo {
  return getEntityData(connectionData, getCounterProfile());
}

/**
 * @deprecated If you are within QuestionTree (or it's children), inject `QuestionTreeDataKey` ... otherwise, inject `ConnectionData` and grab it from current.questions in there
 */
export function getCurrentProfileQuestionsComputed(): ComputedRef<JSONQuestion> {
  return computed(() => getStore().getters["profile/currentProfileQuestions"]);
}

export function getCurrentProfileQuestionsFromConnectionDataComputed(
  connectionData: Ref<ConnectionProfileData>,
): ComputedRef<JSONQuestion> {
  return computed(() => {
    const currentProfile = getCurrentProfile();
    return connectionData.value?.[currentProfile]?.questions || null;
  });
}

export function getHasOverviewTab(currentProfileQuestions: JSONQuestion) {
  const firstTab = head(currentProfileQuestions.children || []);
  return firstTab?.key === SpecialTabs.OVERVIEW;
}

export function getCurrentQuestionsFromConnectionData(connectionData: ConnectionProfileData): JSONQuestion {
  return connectionData?.[getCurrentProfile()]?.questions;
}

export function getOppositeQuestionsFromConnectionData(connectionData: ConnectionProfileData): JSONQuestion {
  return connectionData?.[getOppositeCurrentProfile()]?.questions;
}

export function getCurrentAnswersFromConnectionData(connectionData: ConnectionProfileData): Answers {
  const currentProfile = getCurrentProfile();
  return connectionData?.[currentProfile]?.answers || {};
}

export function getCurrentAnswersFromConnectionDataComputed(connectionData: Ref<ConnectionProfileData>) {
  return computed(() => getCurrentAnswersFromConnectionData(connectionData.value));
}

export function getOppositeAnswersFromConnectionData(connectionData: ConnectionProfileData) {
  const currentProfile = getOppositeCurrentProfile();
  return connectionData?.[currentProfile]?.answers || {};
}

export function getOppositeAnswersFromConnectionDataComputed(connectionData: Ref<ConnectionProfileData>) {
  return computed(() => getOppositeAnswersFromConnectionData(connectionData.value));
}

export function getAnswerFromConnectionData(
  connectionData: ConnectionProfileData,
  whoseProfile: Profile,
  answerKey: string,
  defaultValue: any = null,
) {
  const answerData = connectionData?.[whoseProfile]?.answers || {};
  return answerData[answerKey] || defaultValue;
}

export function getCurrentAnswerFromConnectionData(
  connectionData: ConnectionProfileData,
  answerKey: string,
  defaultValue: any = null,
) {
  return getAnswerFromConnectionData(connectionData, getCurrentProfile(), answerKey, defaultValue);
}

export function getCurrentEntityNameFromConnectionDataComputed(connectionData: Ref<ConnectionProfileData>) {
  return computed(() => {
    const user = getCurrentUser();
    const answers = getCurrentAnswersFromConnectionData(connectionData.value);
    return getFreeTextAnswer(answers[Ent.Name] || "", user.locale);
  });
}

export function getConnectionProgress(connectionData: ConnectionProfileData | undefined): number {
  const status: Stage = connectionData?.connection?.status;
  if (!status || status === Stage.PUBLIC) {
    return 0;
  } else if (status === Stage.INVITE) {
    return 1;
  } else if (status === Stage.ACCEPT) {
    return 1;
  } else if (status === Stage.COLLAB) {
    return 2;
  } else if (status === Stage.CONNECT) {
    return 3;
  } else if (status === Stage.CONNECTED) {
    return 4;
  } else if (status === Stage.DISCONNECTED || status === Stage.DISCONNECT) {
    return 5;
  }
}

export function getConnectionProgressComputed(connectionData: Ref<ConnectionProfileData> | undefined) {
  return computed(() => getConnectionProgress(connectionData?.value));
}

export function getConnectionProgressFromConnectionDataComputedRef(
  connectionData: Ref<ConnectionProfileData> | undefined,
) {
  return getConnectionProgressComputed(connectionData);
}

export function canCancelConnection(connectionData: Ref<ConnectionProfileData>, theirTasks: Task[] = []): boolean {
  const connectionRole = getConnectionRoleFromConnectionDataComputed(connectionData).value;
  const connectionStatus = connectionData.value?.connection?.status;
  const isReconnecting = connectionData.value?.connection?.isReconnecting;
  const requestingEntity = connectionData.value?.connection?.requestingEntity;
  const isRequestingEntity = getUserActiveEntityId() === requestingEntity;
  const isLegacySupplier = connectionData.value?.theirs?.answers?.Supplier_Is_Legacy === "y";
  const user = getCurrentUser();
  const isBusinessOwner = isUserBusinessOwner(connectionData.value?.theirs?.answers, user);
  const total = theirTasks.length;
  const openTasks = filter((t: Task) => t.completed === false, theirTasks);
  const completed = total - openTasks.length;
  const allCounterPartyTasksCompleted = total > 0 && total === completed;
  switch (connectionStatus) {
    case Stage.INVITE:
      return userCanDeleteConnection(user, isLegacySupplier, isBusinessOwner);
    case Stage.DISCONNECT:
      return false;
    case Stage.ACCEPT:
      if (allCounterPartyTasksCompleted) {
        return canDisconnectConnection(connectionStatus, connectionRole, user, isReconnecting, isRequestingEntity);
      } else {
        return isRequestingEntity && userCanDeleteConnection(user, isLegacySupplier, isBusinessOwner);
      }
    case Stage.DISCONNECTED:
      if (!isRequestingEntity) {
        return false;
      }
    // eslint-disable-next-line no-fallthrough
    default:
      return canDisconnectConnection(connectionStatus, connectionRole, user, isReconnecting, isRequestingEntity);
  }
}

export function hasOverviewTab(connectionData: ConnectionProfileData, overrideCurrentProfile?: Profile): boolean {
  const firstTab = connectionData?.[overrideCurrentProfile || getCurrentProfile()]?.questions?.children[0];
  return firstTab ? firstTab.key === SpecialTabs.OVERVIEW : false;
}

export function onConnectionSide(connectionRole: ConnectionRole, profile: Profile) {
  return (
    (connectionRole === ConnectionRole.BUYER && profile === Profile.THEIRS) ||
    (connectionRole === ConnectionRole.SELLER && profile === Profile.MINE)
  );
}

/*export function hasOverviewTab(connectionData: ConnectionProfileData, overrideCurrentProfile?: Profile): boolean {
 const firstTab = connectionData?.[overrideCurrentProfile || getCurrentProfile()]?.questions?.children[0];
 return firstTab ? firstTab.key === SpecialTabs.OVERVIEW : false;
 }*/

export function showOverviewTabComputed(connectionData: Ref<ConnectionProfileData>): ComputedRef<boolean> {
  return computed(() => hasOverviewTab(connectionData.value) && getCurrentProfile() === Profile.THEIRS);
}

// COUNTS
export function getMessageCounts(
  connectionData: ConnectionProfileData,
  question: JSONQuestion,
  which?: Profile,
  instanceIdOverride?: string,
): MessagesElementCount {
  if (connectionData[which || getCurrentProfile()]?.messageCounts) {
    const instanceId = question.key.split(".")[1];
    return find(
      (m: MessageCount) => m.elementId === question._id && m.instanceId === (instanceIdOverride || instanceId),
    )(connectionData[which || getCurrentProfile()].messageCounts);
  } else {
    return {count: 0, unreadCount: 0};
  }
}
export function isUserBusinessOwner(answers: Answers, user): boolean {
  if (!user) {
    return false;
  }
  const answer = Array.isArray(answers?.[Connection.SupplierPrimaryBusinessOwner])
    ? answers?.[Connection.SupplierPrimaryBusinessOwner]?.[0]
    : answers?.[Connection.SupplierPrimaryBusinessOwner];
  if (answer?.userId === user._id) {
    return true;
  }
  return answers?.[Connection.SupplierPrimaryBusinessOwnerEmail] === user.email;
}

export function getNoteCounts(
  connectionData: ConnectionProfileData,
  question: JSONQuestion,
  which?: Profile,
  instanceIdOverride?: string,
): NotesElementCount {
  if (connectionData[which || getCurrentProfile()]?.noteCounts) {
    const instanceId = question.key.split(".")[1];
    return find((n: NoteCount) => n.elementId === question._id && n.instanceId === (instanceIdOverride || instanceId))(
      connectionData[which || getCurrentProfile()].noteCounts,
    );
  } else {
    return {count: 0, unreadCount: 0};
  }
}

export function getReminderCounts(
  connectionData: ConnectionProfileData,
  question: JSONQuestion,
  which?: Profile,
  instanceIdOverride?: string,
): ReminderElementCount {
  if (connectionData[which || getCurrentProfile()]?.noteCounts) {
    const instanceId = question.key.split(".")[1];
    return find(
      (r: ReminderCount) =>
        r.elementId === question._id && (!r.instanceId || r.instanceId === (instanceIdOverride || instanceId)),
    )(connectionData[which || getCurrentProfile()].noteCounts);
  } else {
    return {count: 0, overdueCount: 0};
  }
}

export function getTaskCounts(
  connectionData: ConnectionProfileData,
  question: JSONQuestion,
  which?: Profile,
  instanceIdOverride?: string,
): TaskElementCount {
  if (connectionData[which || getCurrentProfile()]?.taskCounts) {
    const instanceId = question.key.split(".")[1];
    return find((t: TaskCount) => t.elementId === question._id && t.instanceId === (instanceIdOverride || instanceId))(
      connectionData[which || getCurrentProfile()].taskCounts,
    );
  } else {
    return {count: 0};
  }
}

export function getApprovalCounts(
  connectionData: ConnectionProfileData,
  question: JSONQuestion,
  which?: Profile,
  instanceIdOverride?: string,
): SharedElementCount {
  if (connectionData[which || getCurrentProfile()]?.noteCounts) {
    const instanceId = question.key.split(".")[1];
    return find(
      (a: ApprovalCount) =>
        a.elementId === question._id && (!a.instanceId || a.instanceId === (instanceIdOverride || instanceId)),
    )(connectionData[which || getCurrentProfile()].approvalCounts);
  } else {
    return {count: 0};
  }
}

export function getUserById(connectionData: ConnectionProfileData, userId: string, which?: Profile): UserAvatar {
  return connectionData[which || getCurrentProfile()]?.users?.[userId];
}

/**
 * @deprecated
 */
export function getCurrentEntityId(): string {
  return getStore().state?.profile[`${getCurrentProfile()}Entity`]?.entityId;
}

export function getCurrentEntityIdFromConnectionData(connectionData: ConnectionProfileData): string | undefined {
  return getCurrentAnswersFromConnectionData(connectionData)?.entityId;
}

export function getMyEntityNameFromConnectionData(connectionData: ConnectionProfileData) {
  return getFreeTextAnswer(
    getAnswerFromConnectionData(connectionData, Profile.MINE, "Entity_Name"),
    getCurrentUser().locale,
  );
}

/**
 * @deprecated -- doesn't work as expected - use provide with CurrentEntityIdKey
 */
export function getCurrentEntityIdFromRoute(): string {
  return ensureSingleQueryParam(getCurrentRoute().params.entityId);
}

export function getOppositeCurrentProfile(): Profile {
  const currentProfile = getCurrentProfile();
  return currentProfile === Profile.MINE ? Profile.THEIRS : Profile.MINE;
}

export function getCurrentProfileComputed(): ComputedRef<Profile> {
  return computed(() => getCurrentProfile());
}

export function getCurrentEntityInfo(appData: QuestionTreeSectionSpecificData): ExtendedEntityInfo {
  return getCurrentProfile() === Profile.MINE ? appData?.userEntityInfo : appData?.counterPartyEntityInfo;
}

export function getToSupplierOverviewUrl(entityId: string, connectionData: ConnectionProfileData): string {
  return UrlUtility.generateConnectionUrl("", entityId, connectionData.connection?._id, Profile.MINE, {
    lvt: "overview",
  });
}

export function getEditingComputed(): ComputedRef<boolean> {
  return computed(() => getStore().state.cancelSaveBarEditing);
}

/**
 * @deprecated
 */
export function getCancellingOrSavingComputed(): ComputedRef<boolean> {
  return computed(() => getStore().getters["profile/connection/cancelingOrSaving"]);
}

export function getLogoWithFileId(fileId): string {
  return fileId ? `/api/files/${fileId}?thumbnail=true` : "";
}

export function getPrimaryAddress(connectionData: ConnectionProfileData, which: Profile) {
  const addresses = getAnswerFromConnectionData(connectionData, which, Entity.Locations);
  if (addresses) {
    const instanceIds = Object.keys(addresses);
    if (instanceIds.length) {
      const primary = findIndex((instanceId) => {
        return (
          instanceId !== Address.Order &&
          !addresses[instanceId][Internal.DELETED] &&
          addresses[instanceId][MagicAnswerKeys.FIRST] === BooleanAnswers.YES
        );
      }, instanceIds);
      if (primary > -1) {
        return getAnswerFromConnectionData(connectionData, which, Entity.Locations)?.[instanceIds[primary]]?.[
          AddressKeys.Address
        ];
      }
    }
  }
  return {};
}

export function getConnectionIdFromRoute(): string | null | undefined {
  const paramConnectionId = ensureSingleQueryParam(getCurrentRoute().params?.connectionId);
  return paramConnectionId === "none" ? null : paramConnectionId;
}

export function showEditOptionComputed(isInEditMode: ComputedRef<boolean>, elementData: ComputedRef<JSONQuestion>) {
  return computed(
    () =>
      !isInEditMode.value &&
      !childrenAreReadonly(elementData.value) &&
      getCurrentProfile() === Profile.THEIRS &&
      elementData.value.editable,
  );
}

export function isInOverviewTabComputed(parentTabKey: Ref<string>) {
  return parentTabKey.value === SpecialTabs.OVERVIEW;
}

// Connection Tasks
/**
 * @deprecated
 */
export function getConnectionTasksComputed(): ComputedRef<Task[]> {
  return computed(() => getStore().state?.profile?.connection?.tasks);
}

export function parentTopic(elementData: JSONQuestion) {
  return findSubTopic(elementData.parent) || findTopic(elementData.parent) || {};
}

export function getBuyerTypes(): Array<{label: string; value: string}> {
  return getEntityInfo().buyerTypes || [];
}

export function getSellerTypes(): Array<{label: string; value: string}> {
  return getEntityInfo().sellerTypes || [];
}

export function getBuyerTypesPlural(): Array<{label: string; value: string}> {
  const plural = getEntityInfo().buyerTypesPlural;
  if (!isEmpty(plural)) {
    return plural;
  }
  return getEntityInfo().buyerTypes || [];
}

export function getSellerTypesPlural(): Array<{label: string; value: string}> {
  const plural = getEntityInfo().sellerTypesPlural;
  if (!isEmpty(plural)) {
    return plural;
  }
  return getEntityInfo().sellerTypes || [];
}

export function getRealConnectionType(value: string): string | undefined {
  if (value === ConnectionRole.SELLER || getSellerTypes().find((i) => i.value === value)) {
    return ConnectionRole.SELLER;
  } else if (value === ConnectionRole.BUYER || getBuyerTypes().find((i) => i.value === value)) {
    return ConnectionRole.BUYER;
  }
}

export function swapConnectionType(value: string): ConnectionRole {
  return value === ConnectionRole.SELLER ? ConnectionRole.BUYER : ConnectionRole.SELLER;
}

// gets the possible connection search scopes based on the active entity
export function getConnectionSearchScopes(): Array<ConnectionSearchScope | CustomConnectionRole> {
  return [
    ...(getBuyerTypes().length ? getBuyerTypes().map((t) => t.value) : [ConnectionSearchScope.BUYER]),
    ...(getSellerTypes().length ? getSellerTypes().map((t) => t.value) : [ConnectionSearchScope.SELLER]),
  ];
}

export function getPreferredConnectionSearchScopes(): Array<ConnectionSearchScope | CustomConnectionRole> {
  // show search results opposite of user's default role
  if (getConnectionRole() === ConnectionRole.BUYER) {
    return [
      ...(getSellerTypes().length ? getSellerTypes().map((t) => t.value) : [ConnectionSearchScope.SELLER]),
      ConnectionSearchScope.OTHER_SELLER,
    ];
  }
  if (getConnectionRole() === ConnectionRole.SELLER) {
    return [
      ...(getBuyerTypes().length ? getBuyerTypes().map((t) => t.value) : [ConnectionSearchScope.BUYER]),
      ConnectionSearchScope.OTHER_BUYER,
    ];
  }

  return [];
}

// put the opposite of user's connection role on top
export function getConnectionSearchScopeOrder(): Array<ConnectionSearchScope | CustomConnectionRole> {
  const allSellerTypes = [
    ...(getSellerTypes().length ? getSellerTypes().map((t) => t.value) : [ConnectionSearchScope.SELLER]),
    ConnectionSearchScope.OTHER_SELLER,
  ];
  const allBuyerTypes = [
    ...(getBuyerTypes().length ? getBuyerTypes().map((t) => t.value) : [ConnectionSearchScope.BUYER]),
    ConnectionSearchScope.OTHER_BUYER,
  ];

  if (getConnectionRole() === ConnectionRole.BUYER) {
    return [...allSellerTypes, ...allBuyerTypes, ConnectionSearchScope.PUBLIC];
  }

  if (getConnectionRole() === ConnectionRole.SELLER) {
    return [...allBuyerTypes, ...allSellerTypes, ConnectionSearchScope.PUBLIC];
  }
}

export function getCustomConnectionTypeLabel(value: string, type: ConnectionRole): string {
  const types = getBuyerTypes()
    .concat(getSellerTypes())
    .concat([
      {value: ConnectionRole.BUYER, label: translate("connection.buyer")},
      {value: ConnectionRole.SELLER, label: translate("connection.seller")},
    ]);
  const found = types.find((i) => i.value.toLowerCase() === value.toLowerCase());
  return found?.label || translate(`connection.${type}`);
}

export function userCanAddManualTask(connectionData: ConnectionProfileData): boolean {
  const requestingEntity = connectionData?.connection?.requestingEntity;
  const isRequestingEntity = getUserActiveEntityId() === requestingEntity;
  return isRequestingEntity;
}

/**
 * @deprecated
 */
export async function updateEditMode(editKey: string, inEditMode = true) {
  await getStore().dispatch("profile/updateEditMode", {editNamespace: editKey, value: inEditMode});
}

/**
 * @deprecated
 */
export async function profileClickHandler() {
  await getStore().dispatch("profile/saveClickHandler", {});
}

export async function navigateToElement(waitForElement, elementId, instanceId, profileQuestions, suppressFlashEffect) {
  let element = elementId ? find((e) => e._id === elementId, profileQuestions) : null;
  if (!element) {
    // Probably hidden?
    return;
  }

  // If there is only one sub topic then we want to open that. The easiest way to do this is set that as the element
  if (element.type === GroupType.TOPIC && element.children.length === 1) {
    element = element.children[0];
  }

  // If a hidden referenced table, find the first reference to it and navigate to that instead since the source is hidden
  if (pathOr(false, ["hideSourceTable"], element)) {
    element = findNearestNeighbor(
      (q) => q.type === QuestionType.REFERENCE_TABLE && q.optionsAnswerKey === element.key && q.visible,
      profileQuestions,
    );
    const instanceElement = findParent((p) => p.type === GroupType.GROUP_INSTANCE, element);
    instanceId = instanceElement.instance;
  }

  const parentTopic = findParentOfType(GroupType.TOPIC, element);
  const parentSubtopic = findParentOfType(GroupType.SUBTOPIC, element);
  const parentTab = findParentOfType(GroupType.TAB, element);
  let parentGroupItem = instanceId ? findParent((p) => p.subType === GroupSubType.ITEM, element) : null;
  let parentGroupTable = instanceId ? findParent((p) => p.subType === GroupSubType.TABLE, element) : null;

  // Open Topics, Table Instance
  const store = getStore();
  await store.dispatch("profile/updateTab", {value: parentTab.key});

  if (parentTab.key === SpecialTabs.OVERVIEW) {
    nextTick(() => store.commit("profile/lowerVerticalTabValue", "overview"));
  } else {
    await store.dispatch("profile/updateCollapseState", {collapseNamespace: parentTopic.key, value: true});
  }

  await store.dispatch("profile/updateCollapseState", {collapseNamespace: parentTopic.key, value: true});
  if (parentSubtopic) {
    await store.dispatch("profile/updateCollapseState", {collapseNamespace: parentSubtopic.key, value: true});
  }
  if (parentGroupItem) {
    await store.dispatch("profile/updateCollapseState", {
      collapseNamespace: `${parentGroupItem.key}_${instanceId}`,
      value: true,
    });
  }
  if (parentGroupTable) {
    store.commit("profile/tableModalShowStates", {[instanceId]: true});
  }

  // Scroll To Element
  const selector = `#element_${element._id}`;
  const error = await waitForSelector({
    target: waitForElement,
    selector,
    timeout: 1200,
  });

  requestAnimationFrame(() => {
    if (!error) {
      VueScrollTo.scrollTo(selector, 300, {
        offset: -137,
        onDone: (elem) => {
          // setTimeout(() => this.$router.replace({ replace: false }), 300);
          if (!suppressFlashEffect) {
            elem.classList.add("flash-highlight");
          }
          stickyScroll({
            elem,
            offset: -137,
          });
        },
      });
    } else {
      // show popup saying the item isn't currently in their profile
      // todo: this shows up too often
      getToast().warning("This question is not available on graphiteConnect.");
    }
  });

  return {
    element,
    parentTopic,
    parentSubtopic,
    parentTab,
    parentGroupItem,
    parentGroupTable,
    instanceId,
  };
}

export async function getProfile(
  which: Profile,
  entityId: string,
  connectionId?: string,
  connectionRole?: ConnectionRole,
  campaignId?: string,
  answerKeys?: string[],
  includeTinCheck = false,
  resolveUsers = false,
  includeBankRefs = false,
): Promise<ConnectionSideData> {
  const params: QuestionApiParams = {connectionRole, resolveUsers};

  if (connectionId) {
    params.connectionId = connectionId;
  } else {
    params.connectedEntityId = entityId;
  }

  if (answerKeys && answerKeys.length) {
    params.answerKeys = answerKeys;
  }

  if (campaignId) {
    params.currentCampaign = campaignId;
  }

  if (includeTinCheck) {
    params.includeTinCheck = true;
  }

  if (includeBankRefs) {
    params.includeBankRefs = true;
  }

  const response: AxiosResponse<QuestionApiResponse> = await httpGet(`/api/questions/${which}`, params);

  let entity: ExtendedEntityInfo;
  if (entityId) {
    entity = await getEntity(entityId);
  }

  return {entity, ...response.data};
}

export async function getConnectionProfiles(
  connectionId?: string,
  connectedEntityId?: string,
  acknowledgmentElementId?: string,
  changedSinceLastReview?: boolean,
  campaignId?: string,
  afterDataReceivedCallback?: (mineResponse: QuestionApiResponse, theirsResponse: QuestionApiResponse) => {},
): Promise<ConnectionProfileData> {
  // commit("lastCampaignId", campaignId);
  const params: QuestionApiParams = {};
  if (connectionId) {
    params.connectionId = connectionId;
  } else {
    params.connectedEntityId = connectedEntityId;
  }
  const mineEntityId = getStore().state.user.activeEntityId;
  let theirsEntityId = connectedEntityId;
  if (campaignId) {
    params.currentCampaign = campaignId;
  }
  params.resolveUsers = true;

  const connectionProfileAsyncCalls: Promise<AxiosResponse<QuestionApiResponse>>[] = [
    httpGet(`/api/questions/theirs`, {
      ...params,
      excludeConnection: true,
      includeTinCheck: true,
      changesSinceLastReview: changedSinceLastReview,
      includeGiactAnswers: getStore().state.user.isGiactEnable,
      includeBankRefs: true,
    }),
  ];
  if (connectionId) {
    connectionProfileAsyncCalls.push(
      httpGet(`/api/questions/mine`, {
        ...params,
        excludeConnection: true,
        includeGiactAnswers: getStore().state.user.isGiactEnable,
        includeBankRefs: true,
      }),
    );
  }
  const [theirsQuestionResponse, mineQuestionResponse] = await Promise.all(connectionProfileAsyncCalls);

  // JOIN SOCKET ROOMS AGAIN
  if (afterDataReceivedCallback) {
    afterDataReceivedCallback(mineQuestionResponse.data, theirsQuestionResponse.data);
  }

  // SET QUESTIONS AND ANSWERS &  Entity ID Stuff
  if (connectionId) {
    const requestingEntity: string = path(["data", "connection", "requestingEntity"], theirsQuestionResponse);
    const respondingEntity: string = path(["data", "connection", "respondingEntity"], mineQuestionResponse);
    theirsEntityId = requestingEntity === mineEntityId ? respondingEntity : requestingEntity;
  }

  const [mineEntityResponse, theirsEntityResponse] = await Promise.all([
    getEntity(mineEntityId),
    getEntity(theirsEntityId),
  ]);
  const status = path(["data", "connection", "status"], theirsQuestionResponse);
  if (connectionId && (status === Stage.DISCONNECTED || status === Stage.DISCONNECT)) {
    const response = await httpGet(`/api/connections/disconnected_reason_code/${connectionId}`);
    Object.assign(theirsQuestionResponse.data.connection, {
      supplierDisconnectReasonCode: response.data[Connection.SUPPLIER_DISCONNECT_REASON],
    });
  }

  // commit("connection/updateConnection", theirsResponse.data.connection);

  // Corner case when fetching the connection + profile is too slow and the connection update message is sent
  // before this request returns and we have a chance to listen to the socket room
  /*if (theirsResponse.data.connection && theirsResponse.data.connection.pendingTaskProcessing > 0) {
   dispatch("connection/delayedUpdateConnectionAndTasks", {
   connectionId: theirsResponse.data.connection._id,
   onlyIfProcessing: true,
   });
   }*/

  // this.emitter.$emit(ConnectionState.QUESTIONS_LOADED, {});
  // commit("questionsLoaded", true);
  const connection = theirsQuestionResponse.data.connection;

  return {
    mine: {...omit(["connection"], mineQuestionResponse.data), entity: mineEntityResponse},
    theirs: {...omit(["connection"], theirsQuestionResponse.data), entity: theirsEntityResponse},
    current: null, // Not managed here but rather in ProfileWrap
    connection,
  };
}

export async function getConnectionTheirProfile(connectionId?: string, entityId?: string) {
  const params: QuestionApiParams = {
    connectionId,
    resolveUsers: false,
    includeTinCheck: false,
    changesSinceLastReview: false,
  };
  if (!connectionId) {
    params.connectedEntityId = entityId;
  }
  const connectionProfile = await httpGet<QuestionApiResponse>(`/api/questions/theirs`, {
    ...params,
  });
  return {
    theirs: {...connectionProfile.data},
  };
}

export async function getConnectionTasks(connectionId: string, completed?: boolean, limit = 0): Promise<Task[]> {
  try {
    if (connectionId) {
      const response: AxiosResponse<{tasks: Task[]}> = await httpPost("/api/tasks/query", {
        connectionId: connectionId,
        includeAssignedUsers: true,
        includeApprovals: true,
        includeAuditInfo: false,
        includeUpdatedByName: true,
        includeNotes: true,
        includeOwningEntity: true,
        includeMessageCounts: true,
        includeConnectionBlockers: true,
        limit,
        completed,
      });
      return response.data.tasks;
    } else {
      return [];
    }
  } catch (err) {
    handleXhrError(RegisteredErrors.CONNECTION_TASKS, "Could not retrieve mine connection tasks", err);
    return [];
  }
}

export async function getConnectionTheirTasks(
  connectionId: string,
  includeOwningEntity = false,
  completed?: boolean,
  includeInviteConnectionStage = false,
): Promise<Task[]> {
  try {
    if (connectionId) {
      const response: AxiosResponse<{tasks: Task[]}> = await httpPost("/api/tasks/query/theirs", {
        connectionId: connectionId,
        includeUpdatedByName: true,
        includeOwningEntity: includeOwningEntity,
        includeConnectionBlockers: true,
        completed,
        includeInviteConnectionStage,
      });
      return response.data.tasks;
    } else {
      return [];
    }
  } catch (err) {
    handleXhrError(RegisteredErrors.CONNECTION_THEIR_TASKS, "Could not retrieve their connection tasks", err);
    return [];
  }
}

export async function resendSkeletonInvite(connection: string): Promise<number> {
  try {
    const response: AxiosResponse = await httpPost("/api/connections/resendinvite", {connection});
    return response.status;
  } catch (err) {
    handleXhrError(RegisteredErrors.CONNECTION_RESET_SKELETON_INVITE, "Could not resend skeleton invite", err);
  }
}

export async function cancelInvite(connectionId: string, deleteReason: string): Promise<{redirect: boolean}> {
  try {
    await sleep(50); // Give enough time for the activity indicator to show;
    const res: AxiosResponse<{action: ConnectionCancelAction}> = await httpDelete(`/api/connections/${connectionId}`, {
      deleteReason,
    });
    if (res.data.action === ConnectionCancelAction.CANCEL) {
      // reset connection data ???
      return {redirect: true};
    }
    return {redirect: false};
  } catch (err) {
    handleXhrError(RegisteredErrors.CONNECTION_CANCEL_INVITE, "Could not cancel invite", err);
    return {redirect: false};
  }
}

export async function updateInviteeDetails(email: string, name: string, entityId: string, connectionId: string) {
  try {
    const response: AxiosResponse = await httpPost(`/api/entities/${entityId}/updateInviteeDetails`, {
      email,
      name,
      connectionId,
    });
    return response.status === 204;
  } catch (err) {
    handleXhrError(RegisteredErrors.CONNECTION_UPDATE_INVITEEE, "Could not update invitee", err);
    return false;
  }
}
export async function getSupplierContactEmailQuestion(entityId: string) {
  try {
    const response: AxiosResponse = await httpGet(`/api/entities/${entityId}/supplierContactEmailQuestion`);
    return response.data;
  } catch (err) {
    handleXhrError(RegisteredErrors.GLOBAL, "Could not find supplier contact question", err);
    return false;
  }
}

export async function unhidePrivateProfile(entityId: string) {
  try {
    const response: AxiosResponse = await httpPost("/api/entities/unhide", {entityId});
    return response.status === 204;
  } catch (err) {
    handleXhrError(RegisteredErrors.UNHIDE_PRIVATE_PROFILE, "Could not unhide private profile", err);
  }
}

export function joinMessageRooms(
  entityId: string,
  userId: string,
  counts: Ref<QuestionTreeCounts>,
  handlers: Ref<MessageSocketHandlers>,
  connectionId?: string,
): void {
  if (!entityId) {
    return;
  }

  logger.trace(() => `joinMessageRooms ${entityId} ${userId} ${connectionId}`, counts.value);

  const store = getStore<PGStore>();

  handlers.value.insertMessage = (msg) => {
    const elementId = msg.thread?.elementId;
    const instanceId = msg.thread?.instanceId;
    const author = msg.messages?.[0]?.author;

    const elementIndex = findIndex((m: MessageCount) => m.elementId === elementId && m.instanceId === instanceId)(
      counts.value.messages,
    );

    if (elementIndex > -1) {
      counts.value.messages[elementIndex].count++;
      counts.value.messages[elementIndex].unreadCount += author._id !== userId ? 1 : 0;
      logger.trace(`updating message count for ${JSON.stringify(counts.value.messages[elementIndex])}`);
    } else {
      counts.value.messages.push({
        count: 1,
        elementId,
        instanceId,
        unreadCount: author._id !== userId ? 1 : 0,
      });
      logger.trace(`adding message count for ${elementId}:${instanceId}`);
    }
    counts.value = {...counts.value, messages: counts.value.messages};
  };

  handlers.value.deleteMessage = (msg) => {
    msg.messages.forEach(() => {
      const elementId = msg.thread?.elementId;

      const elementIndex = findIndex(propEq("elementId", elementId))(counts.value.messages);

      if (elementIndex > -1) {
        counts.value.messages[elementIndex].count--;
        counts.value.messages[elementIndex].unreadCount--;
      }
    });
    counts.value = {...counts.value, messages: counts.value.messages};
  };

  handlers.value.updateMessage = (event) => {
    event.messages.forEach((msg) => {
      const elementId = event.thread?.elementId;
      const readBy = msg.readBy;
      const elementIndex = findIndex(propEq("elementId", elementId))(counts.value.messages || []);
      if (readBy) {
        const readByUser = findIndex(propEq("_id", userId))(readBy || []);

        if (elementIndex > -1 && readByUser > -1) {
          logger.trace(`marking ${JSON.stringify(counts.value.messages[elementIndex])} read by ${userId}`);
          counts.value.messages[elementIndex].unreadCount = 0;
        }
      }
    });
    counts.value = {...counts.value, messages: counts.value.messages};
  };

  const base: MessagesByTypeParams = {connectionId, entityId, objectType: MessageObjectType.ELEMENT};
  store.join(RoutingKey.messagesByType(base));
  store.socketOn(RoutingKey.messagesByType(base, ChangeType.INSERT), handlers.value.insertMessage);
  store.socketOn(RoutingKey.messagesByType(base, ChangeType.UPDATE), handlers.value.updateMessage);
  store.socketOn(RoutingKey.messagesByType(base, ChangeType.DELETE), handlers.value.deleteMessage);
}

export function leaveMessageRooms(entityId: string, handlers: Ref<MessageSocketHandlers>, connectionId?: string): void {
  const store = getStore<PGStore>();
  const base: MessagesByTypeParams = {entityId, connectionId, objectType: MessageObjectType.ELEMENT};
  store.leave(RoutingKey.messagesByType(base));
  store.socketOff(RoutingKey.messagesByType(base, ChangeType.INSERT), handlers.value.insertMessage);
  store.socketOff(RoutingKey.messagesByType(base, ChangeType.UPDATE), handlers.value.updateMessage);
  store.socketOff(RoutingKey.messagesByType(base, ChangeType.DELETE), handlers.value.deleteMessage);
}

export function joinConnectionRooms(
  entityId: string,
  connectionId: string,
  connectionSocketHandlers: Ref<ConnectionSocketHandlers>,
  connection: Ref<ConnectionProfileData>,
  reloadQuestions: () => Promise<void>,
) {
  if (!connectionId) {
    return;
  }
  const store = getStore<PGStore>();
  connectionSocketHandlers.value.updateConnection = (updated) => {
    if (!connection.value?.connection) {
      return;
    }
    const {
      status: oldStatus,
      updatedAt: oldUpdatedAt,
      pendingTaskProcessing: oldPendingCount,
    } = connection.value.connection;
    if (moment(oldUpdatedAt).isAfter(moment(updated.updatedAt))) {
      logger.debug(() => `NOT updating current connection - it's too old`, updated);
      return;
    }
    logger.debug(() => `updating current connection`, connection.value);

    Object.keys(updated).forEach((key) => {
      connection.value.connection[key] = updated[key];
      if (key === "_id") {
        connection.value.connection["connectionId"] = updated[key];
      }
    });

    if ((oldStatus !== updated.status || oldPendingCount) && !updated.pendingTaskProcessing) {
      logger.debug(() => `connection phase changed - refreshing profiles`);
      reloadQuestions().catch((err) => logger.error(`error refreshing questions`, err));
      // TODO: Figure out this campaign newly registered business
      // dispatch("profile/refresh", {connectionId: state._id, campaignId: state.newlyRegisteredCampaign}, {root: true});
      // commit("newlyRegisteredCampaign", "");
    }
  };

  store.join(RoutingKey.connection({connectionId}));
  store.socketOn(
    RoutingKey.connection({connectionId, evt: ChangeType.UPDATE}),
    connectionSocketHandlers.value.updateConnection,
  );
}

export function leaveConnectionRooms(
  entityId: string,
  connectionId: string,
  connectionHandlers: Ref<ConnectionSocketHandlers>,
) {
  if (!connectionId) {
    return;
  }
  const store = getStore<PGStore>();
  store.leave(RoutingKey.connection({connectionId}));
  store.socketOff(
    RoutingKey.connection({connectionId, evt: ChangeType.UPDATE}),
    connectionHandlers.value.updateConnection,
  );
}

export async function joinAnswerRooms(
  entityId: string | undefined,
  connectionId: string | undefined,
  answerSocketHandlers: Ref<AnswerSocketHandlers>,
  updateAnswers: (JSONObject) => Promise<void>,
): Promise<void> {
  const store = getStore<PGStore>();
  const user = getCurrentUser();
  if (!entityId || !user || user.isDataShareUser || isExcel) {
    return;
  }

  answerSocketHandlers.value.updateAnswers = async (wsResponse) => {
    logger.info("updateAnswers", wsResponse);
    const newAnswers = wsResponse.answers;
    if (!newAnswers) {
      return;
    }
    await updateAnswers(newAnswers);
  };

  const joined = [];
  const roomKey = RoutingKey.answers({entityId, connectionId: "*"});
  store.join(roomKey, {connectionId});
  joined.push(store.whenRoomJoined(roomKey));
  store.socketOn(roomKey, answerSocketHandlers.value.updateAnswers);
  await Promise.all(joined); // this should block to allow all server-side defaults to update the UI before continuing
}

export function leaveAnswerRooms(
  entityId: string,
  connectionId: string,
  answerSocketHandlers: Ref<AnswerSocketHandlers>,
) {
  const store = getStore<PGStore>();
  const roomKey = RoutingKey.answers({entityId, connectionId: "*"});
  store.leave(roomKey);
  store.socketOff(roomKey, answerSocketHandlers.value.updateAnswers);
}

export async function joinKitAnswerRooms(
  entityId: string | undefined,
  kitType: KitType,
  kitId: string,
  answerSocketHandlers: Ref<AnswerSocketHandlers>,
  updateAnswers: (JSONObject) => Promise<void>,
): Promise<void> {
  const store = getStore<PGStore>();
  const user = getCurrentUser();
  if (!entityId || !user || user.isDataShareUser || isExcel) {
    return;
  }

  answerSocketHandlers.value.updateAnswers = async (wsResponse) => {
    logger.info("updateAnswers", wsResponse);
    const newAnswers = wsResponse.answers;
    if (!newAnswers) {
      return;
    }
    await updateAnswers(newAnswers);
  };

  const joined = [];
  const roomKey = RoutingKey.kitAnswers({entityId, kitType, kitId});
  store.join(roomKey);
  joined.push(store.whenRoomJoined(roomKey));
  store.socketOn(roomKey, answerSocketHandlers.value.updateAnswers);
  await Promise.all(joined); // this should block to allow all server-side defaults to update the UI before continuing
}

export function leaveKitAnswerRooms(
  entityId: string,
  kitType: KitType,
  kitId: string,
  answerSocketHandlers: Ref<AnswerSocketHandlers>,
) {
  const store = getStore<PGStore>();
  const roomKey = RoutingKey.kitAnswers({entityId, kitType, kitId});
  store.leave(roomKey);
  store.socketOff(roomKey, answerSocketHandlers.value.updateAnswers);
}

export async function saveProfileData(
  saveData,
  pertainingToEntityId,
  socketId?,
  connectionId?,
  saveType?: SaveType,
  bypassDuplicateTaxId?: boolean,
) {
  if (saveType) {
    saveData.__saveType = saveType;
  }
  saveData.socketId = socketId;

  saveData = cloneDeep(saveData);
  pruneHydratedAnswerFields(saveData);
  let patchObj: PatchAnswersObject = connectionId
    ? {
        connectionId,
        pertainingToEntityId,
        ...saveData,
        bypassDuplicateTaxId,
      }
    : {
        pertainingToEntityId,
        ...saveData,
        bypassDuplicateTaxId,
      };

  const result = await httpPatch("/api/answers", patchObj);
  let instanceIdMap = {};
  if (result.status === 200) {
    instanceIdMap = result.data;
    return instanceIdMap;
  }
}

export async function getAnswersChangedSinceLastReview(
  connectionId,
  topicElementId,
  initializedQuestionTree: JSONQuestion,
) {
  const result = <AxiosResponse<QuestionChangesSinceLastApiResponse>>(
    await httpGet("/api/questions/changes_since_review", {
      topicElementId,
      connectionId,
    })
  );
  let changedElementArray = [];
  if (result && result.data && Array.isArray(result.data.changedSinceLastReview)) {
    changedElementArray = result.data.changedSinceLastReview.map((e) => e.elementId);
  }
  const foundVisibleElements = filterQuestions(
    (q) => changedElementArray.indexOf(q._id) > -1 && q.visible,
    initializedQuestionTree,
  );

  return {
    reviewChangeAnswersCount: Array.isArray(foundVisibleElements) ? foundVisibleElements.length : 0,
    results: result.data,
  };

  /*commit(`${state.currentProfile}/reviewChangeAnswers`, {
   topicElementId,
   value: Array.isArray(foundVisibleElements) ? foundVisibleElements.length : 0,
   });
   dispatch("theirs/setChangesSinceReview", {changes: result.data});*/
}

// If you have an option GetOptionsMethod.GET_OPTIONS_FROM_GROUP_INSTANCES then you need to pass in: initializedQuestionData.
export function getConnectionDataLite(
  connectionData: ConnectionProfileData,
  initializedQuestionData?: JSONQuestion,
): ConnectionDataLite {
  const profile = getCurrentProfile();
  const opposite = oppositeProfile(profile);
  return {
    ...{
      answers: connectionData?.[profile]?.answers,
      questions: initializedQuestionData || connectionData?.[profile]?.questions,
    },
    counter: connectionData?.[opposite]
      ? {answers: connectionData[opposite]?.answers, questions: connectionData[opposite]?.questions}
      : null,
  };
}

export async function connectionInvite(
  requestingEntity: string,
  respondingEntity: string,
  requestingRole: ConnectionRole,
  inviteeCustomizedRole?: string,
  initialAnswersForConnection?: Answers,
  inviteeRole?: ConnectionRole | CustomConnectionRole,
) {
  const defaultRespondingRole = getOppositeConnectionRole(requestingRole);
  const connection = {
    requestingEntity,
    respondingEntity,
    requestingRole,
    respondingRole: inviteeRole || defaultRespondingRole,
    status: Stage.INVITE,
    inviteeCustomizedRole,
    initialAnswersForConnection,
  };
  const saved: AxiosResponse<{connection: ConnectionWithNames}> = await httpPost("/api/connections", {
    data: connection,
  });
  return saved.data.connection;
}

export function getLabelToInviteNewConnection(actorRole: ConnectionRole, upperCase?: boolean): string {
  const role = getOppositeConnectionRoleOrBoth(actorRole);
  return {
    [ConnectionRole.BUYER]: translate(upperCase ? "home.role_type_buyer_upper" : "home.role_type_buyer"),
    [ConnectionRole.SELLER]: translate(upperCase ? "home.role_type_seller_upper" : "home.role_type_seller"),
    [ConnectionRole.BOTH]: translate(upperCase ? "home.role_type_both_upper" : "home.role_type_both"),
  }[role];
}

export async function connectionApprove(connectionId: string, connectionStage: Stage) {
  await httpPut(`/api/connections/approval/${connectionId}`, {status: connectionStage});
}

export async function getConnection(connectionId: string): Promise<ConnectionWithNames> {
  const response: AxiosResponse<{connection: ConnectionWithNames}> = await httpGet(`/api/connections/${connectionId}`);
  return response.data.connection;
}

export async function getTicketQuestionTree(connectionId: string): Promise<QuestionsAnswers> {
  const response: AxiosResponse<QuestionsAnswers> = await httpGet(
    `/api/connections/${connectionId}/ticket-question-tree`,
  );
  return response.data;
}

export async function reconnect(connectionId: string): Promise<void> {
  await httpPut(`/api/connections/reconnect/${connectionId}`, {});
}

export async function lookupConnection(publicId: string): Promise<ConnectionWithNames> {
  const response: AxiosResponse<{connection: ConnectionWithNames}> = await httpGet(`/api/connections/gid/${publicId}`);
  return response.data.connection;
}

export async function getConnectionPdf(url: string, name: string): Promise<string> {
  try {
    const response: AxiosResponse<{fileId: string}> = await httpPost(`/api/connections/pdf`, {url, name});
    return response.data?.fileId;
  } catch (e) {
    handleXhrError(RegisteredErrors.GET_CONNECTION_PDF, `Unable to get connection pdf`, e);
  }
}

export async function setSkipIdentityVerification(connectionId: string, skip: boolean, reason: string) {
  try {
    await httpPost(`/api/connections/identity_skip`, {
      connectionId,
      skip,
      reason,
    });
  } catch (e) {
    handleXhrError(
      RegisteredErrors.SET_CONNECTION_SKIP_IDENTITY_VERIFICATION,
      `Unable to set identity verification`,
      e,
    );
  }
}

export function getConnectionType(connection: ConnectionDocument): string {
  const customType = connection[Internal.CUSTOMIZED_CONNECTION_ROLE] || "";
  if (customType) {
    return customType; // don't use getCustomConnectionTypeLabel because it's already a label
  } else {
    return translate(
      connection.pertainingToEntityRole === ConnectionRole.BUYER
        ? "sign_up.requesting_role_seller"
        : "sign_up.requesting_role_buyer",
    );
  }
}
