import {BasicEntityInfoLite} from "./entity";
import {Localizable} from "./index";
import type {AuditUserInfo} from "./user";

export interface ListDTO {
  _id: string;
  key: string;
  owner: BasicEntityInfoLite;
  items: ListItemDTO[];
  public?: boolean;
  hideFromAdmin?: boolean;
  needsTranslation?: boolean;
  columns: string[];
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
}
export interface APIList {
  _id: string;
  key: string;
  owner: BasicEntityInfoLite;
  items: ListItemDTO[];
  public?: boolean;
  needsTranslation?: boolean;
  columns?: string[];
  updatedBy?: AuditUserInfo | string;
  createdBy?: AuditUserInfo | string;
  updatedAt?: string;
  createdAt?: string;
}

export interface DehydratedListDTO extends Omit<ListDTO, "owner"> {
  owner: string;
}

export interface TranslatedList extends Omit<ListDTO, "items"> {
  items: TranslatedListItem[];
}

export interface TranslatedDehydratedList extends Omit<DehydratedListDTO, "items"> {
  items: TranslatedListItem[];
}

export type ListLookup = Record<string, string | undefined>;

export interface ListItemDTO {
  id: string;
  label: Localizable<string>;
  value: string;
  lookup?: ListLookup;
  children: ListItemDTO[];
}

export enum ListItemLabelType {
  Translated = "Translated",
  EnglishFallback = "EnglishFallback",
  FallbackLocalization = "FallbackLocalization",
}

export interface TranslatedListItem extends Omit<ListItemDTO, "label" | "children"> {
  label: string;
  children: TranslatedListItem[];
}

export interface QuestionList {
  key: string;
  parents: string[];
}

// there's a weird quirk of TypeScript where the below as an interface won't match `JSONObject`, but a type will
// tslint:disable-next-line:interface-over-type-literal
export type ListOption = {
  value: string;
  label: string;
};

// there's a weird quirk of TypeScript where the below as an interface won't match `JSONObject`, but a type will
// tslint:disable-next-line:interface-over-type-literal
export type ListConfig = {
  key: string;
  answerPath: ListAnswerPathConfig[];
  owner?: string; // For overrides where the list owner is different from the question owner
};

// there's a weird quirk of TypeScript where the below as an interface won't match `JSONObject`, but a type will
// tslint:disable-next-line:interface-over-type-literal
export type ListAnswerPathConfig = {
  type: ListAnswerType;
  value: string;
};

export enum ListAnswerType {
  Literal = "Literal",
  Key = "Key",
}
