import {getCurrentProfile} from "@/composables/connections/get-current.profile";
import {ConnectionDataKey} from "@/composables/connections/injections";
import type {ConnectionProfileData} from "@/composables/connections/types";
import {handleXhrError} from "@/composables/errorHandling";
import {RegisteredErrors} from "@/composables/errorHandling/registeredErrors";
import {getStore} from "@/composables/get.store";
import {injectStrict} from "@/composables/provideInject";
import {getParentTopic} from "@/composables/questions/questionTree";
import type {
  TwoFaNonProfileOptions,
  TwoFaOptions,
  TwoFaProfileCheckInput,
  TwoFaProfileOptions,
} from "@/composables/questions/types";
import {httpGet, httpPost, httpPut} from "@/composables/xhr";
import factory from "@/logging";
import type {BasicEntityInfo} from "pg-isomorphic/api/entity";
import type {
  BasicUser,
  Set2FAPhoneNumberPatch,
  Set2FAPhoneNumberResponse,
  TwoFactorOptions,
  ValidationOption,
} from "pg-isomorphic/api/user";
import {TwoFaTopicPermission} from "pg-isomorphic/api/user";
import {ElementType, ErrorCode, GroupType, QuestionType} from "pg-isomorphic/enums";
import {HttpResponseCodes} from "pg-isomorphic/enums/http";
import {TwoFactorMethod} from "pg-isomorphic/enums/users";
import {find as findQuestion, findTopic, processTreeTopDown} from "pg-isomorphic/profile";
import {ProfileGroup} from "pg-isomorphic/profile/group";
import type {JSONQuestion} from "pg-isomorphic/utils";
import type {Ref} from "vue";
import {ref} from "vue";

const logger = factory.getLogger("2Fa");

export function findSecureSiblings(questionData: JSONQuestion) {
  let parent = getParentTopic(questionData);
  if (questionData.parent?.type === ElementType.GROUP_INSTANCE) {
    // handle groups
    parent = questionData.parent;
  }
  const secureChildren = [];
  if (parent) {
    processTreeTopDown((c: JSONQuestion) => {
      if (c.secured && c.type !== QuestionType.OBSCURED) {
        secureChildren.push(c);
      }
    })(parent);
  }
  return secureChildren;
}

export function findSecureChildren(questionData: JSONQuestion) {
  const secureChildren = [];
  processTreeTopDown((c: JSONQuestion) => {
    if (c.secured && c.type !== QuestionType.OBSCURED) {
      secureChildren.push(c);
    }
  })(questionData);
  return secureChildren;
}

export function hasObscuredChildren(questionData: JSONQuestion) {
  let hasObscured = false;
  processTreeTopDown((c: JSONQuestion) => {
    if (hasObscured) {
      return;
    }

    if (c.type === QuestionType.OBSCURED && !c.calculation) {
      hasObscured = true;
    }
  })(questionData);
  return hasObscured;
}

export function instanceKeysHaveSecureChildren(instanceKeys: string[], questionData: JSONQuestion): boolean {
  for (const instanceKey of instanceKeys) {
    const groupInstances = ProfileGroup.findGroupInstanceQuestions(instanceKey, questionData);
    for (const groupInstance of groupInstances) {
      const secureChildren = findSecureChildren(groupInstance);
      if (secureChildren.length > 0) {
        return true;
      }
    }
  }
}

export function groupHasSecureChildren(groupKey: string, originalQuestionData: JSONQuestion): boolean {
  const groupQuestionTree = findQuestion(
    (q: JSONQuestion) => q.type === GroupType.GROUP && q.key === groupKey,
    originalQuestionData,
  );
  if (!groupQuestionTree) {
    logger.error(`groupHasSecureChildren: group ${groupKey} not found`);
    return false;
  }
  const secureChildren = findSecureChildren(groupQuestionTree);
  if (secureChildren.length > 0) {
    logger.trace(`groupHasSecureChildren: group ${groupKey} has ${secureChildren.length} secure children`);
    return true;
  }

  logger.trace(`groupHasSecureChildren: group ${groupKey} has no secure children`);
  return false;
}

export function findTopicIdsForInstanceKeys(instanceKeys: string[], questionData: JSONQuestion): string[] {
  const topicIds = new Set<string>();
  for (const instanceKey of instanceKeys) {
    const groupInstances = ProfileGroup.findGroupInstanceQuestions(instanceKey, questionData);
    for (const groupInstance of groupInstances) {
      const topic = getParentTopic(groupInstance);
      if (topic) {
        topicIds.add(topic._id);
      }
    }
  }
  return Array.from(topicIds);
}

export function topicPermissionFromEditMode(editMode: boolean | undefined): TwoFaTopicPermission {
  return !editMode ? TwoFaTopicPermission.VIEW : TwoFaTopicPermission.EDIT;
}

export function topicPermissionUnlocked(
  editMode: boolean | undefined,
  isBankApproval: boolean,
  treatUserAsIdentityVerified: boolean,
): TwoFaTopicPermission {
  if (isBankApproval) {
    return TwoFaTopicPermission.VIEW;
  }
  if (treatUserAsIdentityVerified) {
    return TwoFaTopicPermission.EDIT;
  }
  return !editMode ? TwoFaTopicPermission.VIEW : TwoFaTopicPermission.EDIT;
}

// There are 2 kinds of 2FA checks:
//   init2FACheck: for admin/personal editing
//   init2FAProfileCheck: for profile or connection (accessed by setupInit2FAProfile)
export function init2FACheck({onVerified, onCancel, section}: TwoFaNonProfileOptions) {
  // Ignore promise warning with void.
  void getStore().dispatch("twoFactor/initNonProfile", {onVerified, onCancel, section});
}

// Call setupInit2FAProfile() to call this function.
function init2FAProfileCheck(
  input: TwoFaProfileCheckInput,
  {
    connectionId,
    currentProfile,
    counterpartyEntityInfo,
    onVerified,
    onVerifiedAndQuestionsLoaded,
    onCancel,
    forceIdVerification,
  }: TwoFaProfileOptions,
) {
  const commonParams = {
    editMode: input.editMode,
    connectionId: connectionId || input.question?.connectionIdOverride,
    currentProfile,
    counterpartyEntityInfo,
    onVerified,
    onCancel,
    forceIdVerification,
  };
  if (input.question) {
    const questionData = input.question;
    const topic = findTopic(questionData.parent) || questionData.topicOverride;
    logger.trace(`init2FAProfileCheck`, questionData, topic);
    const siblings = findSecureSiblings(questionData);
    void getStore().dispatch("twoFactor/init", {
      siblings,
      topicElement: topic,
      securedForApproval: questionData.securedForApproval,
      onVerifiedAndQuestionsLoaded,
      ...commonParams,
    });
  } else if (input.securityRoles) {
    void getStore().dispatch("twoFactor/init", {
      securityRoles: input.securityRoles,
      ...commonParams,
    });
  } else if (input.instanceKeys) {
    void getStore().dispatch("twoFactor/init", {
      instanceKeys: input.instanceKeys,
      serverSideTopicIds: input.serverSideTopicIds,
      ...commonParams,
    });
  } else {
    throw new Error(`Invalid input for init2FAProfileCheck`);
  }
}

export function getCounterpartyInfoIfGraphiteCustomer(
  connectionData: Ref<ConnectionProfileData>,
): BasicEntityInfo | undefined {
  let counterpartyEntityInfo: BasicEntityInfo;
  const theirInfo = connectionData.value?.theirs?.entity;
  if (theirInfo && theirInfo.isGraphiteCustomer) {
    counterpartyEntityInfo = theirInfo;
  }
  return counterpartyEntityInfo;
}

// Accept interface TwoFaOptions and then add more info so that internally uses interface TwoFaProfileOptions.
export function setupInit2FAProfile(): (input: TwoFaProfileCheckInput, options: TwoFaOptions) => void {
  const currentProfile = getCurrentProfile();
  const connectionData: Ref<ConnectionProfileData> = injectStrict(ConnectionDataKey, ref(null));
  const counterpartyEntityInfo = getCounterpartyInfoIfGraphiteCustomer(connectionData);

  logger.trace(`setupInit2FAProfile`, currentProfile, connectionData.value, counterpartyEntityInfo);
  return (input, options) =>
    init2FAProfileCheck(input, {
      currentProfile,
      connectionId: connectionData.value?.connection?._id,
      counterpartyEntityInfo,
      ...options,
    });
}

export async function set2FAPhoneNumber(
  update: Set2FAPhoneNumberPatch,
): Promise<Set2FAPhoneNumberResponse | undefined> {
  try {
    const response = await httpPost<Set2FAPhoneNumberResponse>(`/api/users/2fa/set-phone-number`, update);
    return response.data;
  } catch (e) {
    handleXhrError(RegisteredErrors.UPDATE_USER, `Could not update phone number`, e);
    return undefined;
  }
}

export async function getTwoFactorOptions(): Promise<TwoFactorOptions> {
  try {
    const response = await httpGet<TwoFactorOptions>("/api/users/2fa/options");
    return response.data;
  } catch (e) {
    handleXhrError(RegisteredErrors.UPDATE_USER, `Could not get two factor options`, e);
  }
}

export async function verifyTwoFactorCode(method: TwoFactorMethod, verifyCode: string): Promise<ErrorCode | undefined> {
  try {
    let response;
    if (method === TwoFactorMethod.OTP) {
      response = await httpPut(`/api/users/2fa/verify.otp/${verifyCode}`, {});
    } else {
      response = await httpPut(`/api/users/2fa/verify/${verifyCode}`, {});
    }
    if (response.status === HttpResponseCodes.OK) {
      logger.debug(`2fa authenticated`, response.data.twoFactorAuthVerified);
      const store = getStore();
      await store.dispatch("completeSignIn", {signInResponse: response.data}, {root: true});
      await store.dispatch("setTwoFactorVerified", true, {root: true});
      return undefined;
    } else {
      logger.debug(`status code incorrect ${response.status} ${response.statusText}`);
      return ErrorCode.UNKNOWN_ERROR;
    }
  } catch (e) {
    if (e.response && e.response.data) {
      logger.debug("error verifying code", e.response.data);
      return e.response.data.code || ErrorCode.UNKNOWN_ERROR;
    } else {
      logger.error("error occurred processing verify results", e);
      return ErrorCode.UNKNOWN_ERROR;
    }
  }
}

export async function verifyTwoFactorProve(proveScoreId: string): Promise<void> {
  try {
    const response = await httpPut<BasicUser>(`/api/users/2fa/verify.prove.initial/${proveScoreId}`, {});
    if (response.status === HttpResponseCodes.OK) {
      logger.debug(`2fa authenticated`, response.data.twoFactorAuthVerified);
      await getStore().dispatch("completeSignIn", {signInResponse: response.data}, {root: true});
      await getStore().dispatch("setTwoFactorVerified", true, {root: true});
    } else {
      logger.debug(`status code incorrect ${response.status} ${response.statusText}`);
    }
  } catch (e) {
    handleXhrError(RegisteredErrors.UPDATE_USER, `Could not check Prove 2fa`, e);
  }
}

export async function sendTwoFactorCode(option: ValidationOption): Promise<ErrorCode | undefined> {
  try {
    await httpPost("/api/users/2fa/send", option);
    return undefined;
  } catch (e) {
    handleXhrError(RegisteredErrors.UPDATE_USER, `Could not send two factor code`, e);
    return ErrorCode.UNKNOWN_ERROR;
  }
}
