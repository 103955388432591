<template>
  <QuestionInput
    :modelValue="modelValue"
    @update:modelValue="emit('update:modelValue', $event)"
    :read-only="readOnly"
    :edit-mode="editMode"
    :question-data="questionData"
    @updateValidState="emit('updateValidState', $event)"
    @updateValidationState="emit('updateValidationState', $event)"
    :hide-actions="true"
    @blur="emit('blur', $event)"
    @focus="emit('focus', $event)"
  />
</template>

<script lang="ts" setup>
import QuestionInput from "@/components/questions/QuestionInput.vue";
import type {QuestionData, ValidationRules, ValidationState} from "@/composables/questions/types";
import {getRandomId} from "@/composables/utils";
import type {LocaleObject} from "pg-isomorphic/api/answers";
import {QuestionType} from "pg-isomorphic/enums";
import {ValidationType} from "pg-isomorphic/enums/question";
import type {QuestionOption} from "pg-isomorphic/options";
import type {ComputedRef, PropType} from "vue";
import {computed, defineEmits, defineProps} from "vue";

const props = defineProps({
  inputKey: {
    type: String,
    default: () => getRandomId(),
  },
  modelValue: {
    type: [Array, String, Object, Date, Boolean, Number],
  },
  type: {
    type: String as PropType<QuestionType>,
    required: true,
  },
  label: {
    type: [String, Object] as PropType<string | LocaleObject>,
  },
  options: {
    type: Array as PropType<QuestionOption[]>,
    default: () => [],
  },
  multiple: {
    type: Boolean,
  },
  editMode: {
    type: Boolean,
    default: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  validationType: {
    type: String as PropType<ValidationType>,
  },
  validationMin: {
    type: Number,
  },
  validationMax: {
    type: Number,
  },
  validationLength: {
    type: Number,
  },
  validationError: {
    type: String,
  },
  hintText: {
    type: [String, Object] as PropType<string | LocaleObject>,
  },
  minNumRows: {
    type: Number,
  },
  password: {
    type: Boolean,
    default: false,
  },
  // for Textarea
  autoResize: {
    type: Boolean,
    default: true,
  },
  // use `hintText` for text input placeholders
  selectPlaceholder: {
    type: String,
  },
});

const emit = defineEmits<{
  (event: "updateValidState", validState: boolean): void;
  (event: "updateValidationState", validationState: ValidationState): void;
  (event: "focus", focusEvent: FocusEvent): void;
  (event: "blur", focusEvent: FocusEvent): void;
  (event: "update:modelValue", value: any): void;
}>();

const validationTypeActual: ComputedRef<ValidationType> = computed(() => {
  if (props.validationType) {
    return props.validationType;
  } else if (props.type === QuestionType.TEXT || props.type === QuestionType.TEXTAREA) {
    return ValidationType.STRING;
  } else {
    return undefined;
  }
});

const questionData: ComputedRef<QuestionData> = computed(() => {
  const question: QuestionData = {
    _id: props.inputKey,
    key: props.inputKey,
    type: props.type,
    required: props.required,
    visible: true,
    hintText: props.hintText,
    multiple: props.multiple,
    validationError: props.validationError,
    label: props.label,
    hideLabel: !props.label,
    minNumRows: props.minNumRows,
    password: props.password,
    autoResize: props.autoResize,
    // this is just for selects, use `hintText` for text inputs
    placeholder: props.selectPlaceholder,
  };

  if (
    props.type === QuestionType.SELECT ||
    props.type === QuestionType.USER_SELECT ||
    props.type === QuestionType.RADIO ||
    props.type === QuestionType.RADIO_OTHER ||
    props.type === QuestionType.CHECKBOX ||
    props.type === QuestionType.CHECKBOX_OTHER ||
    props.type === QuestionType.CHECKLIST_VIEW ||
    props.type === QuestionType.TWO_LIST_MULTI_SELECT ||
    props.type === QuestionType.CONTACT
  ) {
    question.options = props.options;
  }

  if (validationTypeActual.value) {
    const validationRule: ValidationRules = {
      type: validationTypeActual.value,
      min: props.validationMin,
      max: props.validationMax,
      length: props.validationLength,
      optional: props.type === QuestionType.RADIO ? true : !props.required,
      empty: props.type === QuestionType.RADIO ? true : !props.required,
    };

    question.validation = {
      rules: validationRule,
    };
  } else if (props.required) {
    console.error("FormInput required flag will not work without a validationType");
  }

  return question;
});
</script>
