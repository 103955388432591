import type {ConnectionProfileData} from "@/composables/connections/types";
import type {QuestionTableColumn} from "@/composables/questions/questionTableTypes";
import type {QuestionTreeCountTypes, UpdateQuestionTreeCountsAddParams} from "@/composables/questions/types";
import type {ConnectionIdentityScore} from "pg-isomorphic/api/entity";
import type {MasterDataType} from "pg-isomorphic/api/masterData";
import type {Task} from "pg-isomorphic/api/tasks";
import type {Profile} from "pg-isomorphic/enums";
import type {JSONQuestion} from "pg-isomorphic/utils";
import type {ComputedRef, InjectionKey, Ref} from "vue";

export const topicOwningRoleInjectionKey: InjectionKey<string> = Symbol("topicId");
export const TopicInternalUseBannerTracker: InjectionKey<Ref<string[]>> = Symbol("topicInternalUseBannerTracker");
export const ConnectionDataKey: InjectionKey<Ref<ConnectionProfileData>> = Symbol("connectionData");
export const InitializedQuestionTreeKey: InjectionKey<Ref<JSONQuestion>> = Symbol("initConnectionData");
export const LoadConnectionDataKey: InjectionKey<() => void> = Symbol("LoadConnectionData");
export const ShowCannotFilterModalKey: InjectionKey<Ref<boolean>> = Symbol("showCannotFilterModal");
export const ProfileSearchKey: InjectionKey<Ref<string>> = Symbol("ProfileSearch");
export const TheirTasksKey: InjectionKey<Ref<Task[]>> = Symbol("TheirTasks");
export const TasksKey: InjectionKey<Ref<Task[]>> = Symbol("Tasks");
export const ReceivedTasksKey: InjectionKey<Ref<boolean>> = Symbol("ReceivedTasks");
export const TaskDrawerOpenKey: InjectionKey<Ref<boolean>> = Symbol("TaskDrawerOpen");
export const UpdateSpecificAnswerKey: InjectionKey<(key: string, value: any) => void> = Symbol("UpdateSpecificAnswer");
export const CachedInternalTicketTablesKey: InjectionKey<Ref<JSONQuestion[]>> = Symbol("CachedInternalTicketTables");
export const CachedSupplierTicketTablesKey: InjectionKey<Ref<JSONQuestion[]>> = Symbol("CachedSupplierTicketTables");
export const UpdateConnectionEntityKey: InjectionKey<(entityId: string, which: Profile) => void> =
  Symbol("UpdateConnectionEntity");
export const UpdateConnectionDataKey: InjectionKey<(data: Partial<ConnectionProfileData>) => void> =
  Symbol("UpdateConnectionData");
export const ReloadConnectionTasksKey: InjectionKey<() => Promise<void>> = Symbol("ReloadConnectionTasks");
export const ReloadConnectionKey: InjectionKey<() => Promise<void>> = Symbol("ReloadConnection");
export const JoinConnectionRoomsKey: InjectionKey<() => void> = Symbol("JoinConnectionRooms");
export const LoadTasksDataKey: InjectionKey<() => void> = Symbol("LoadTasksData");
export const UpdateCurrentCampaignKey: InjectionKey<(id: string) => void> = Symbol("UpdateCurrentCampaign");
export const CurrentCampaignKey: InjectionKey<Ref<string>> = Symbol("currentCampaign");
export const UpdateQuestionTreeCountsKey: InjectionKey<
  (
    element: string,
    instance: string,
    change: number,
    type: QuestionTreeCountTypes,
    params?: UpdateQuestionTreeCountsAddParams,
  ) => void
> = Symbol("UpdateQuestionTreeCounts");
export const ShowUnansweredRequiredKey: InjectionKey<ComputedRef<boolean>> = Symbol("showUnansweredRequired");
export const AppendQuestionTableColumnsKey: InjectionKey<ComputedRef<{[tableKey: string]: QuestionTableColumn[]}>> =
  Symbol("appendQuestionTableColumns");
export const AppendQuestionTableRowsKey: InjectionKey<
  ComputedRef<{[tableKey: string]: {[instanceKey: string]: {[answer: string]: any}}}>
> = Symbol("appendQuestionTableRows");
export const MasterDataTypesKey: InjectionKey<Ref<MasterDataType[]>> = Symbol("MasterDataTypes");
export const CurrentMasterData: InjectionKey<Ref<MasterDataType>> = Symbol("CurrentMasterData");
export const IdentityScoreKey: InjectionKey<Ref<ConnectionIdentityScore>> = Symbol("IdentityScore");
