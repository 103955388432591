<template>
  <div class="supplier-survey">
    <div class="supplier-survey-header">
      <div class="header-icon">
        <font-awesome-icon :icon="headerIcon" />
      </div>
      <div class="header-text">{{ headerText }}</div>
    </div>

    <div class="supplier-survey-body">
      <div class="body-text">
        <p v-html="sanitizedBody" />
      </div>

      <RatingSelector :label="$t('supplier_survey.rating')" v-model="results.rating" />

      <FormInput
        v-delay-focus="100"
        :minNumRows="3"
        :hintText="$t('general.optional')"
        :type="QuestionType.TEXTAREA"
        v-model="results.comments"
        :label="$t('supplier_survey.comments')"
        :validationMax="1000"
        @updateValidState="($event) => (commentsValid = $event)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import FormInput from "@/components/questions/FormInput.vue";
import RatingSelector from "@/components/shared/RatingSelector.vue";
import type {SupplierSurveyOptions} from "@/composables/supplierSurvey";
import type {SupplierSurveyResults} from "pg-isomorphic/api/suppliersurvey";
import {QuestionType} from "pg-isomorphic/enums";
import {computed, ref, watch} from "vue";
import {sanitizeTextForHtml} from "pg-isomorphic/utils/text";

const props = defineProps<SupplierSurveyOptions>();

const emit = defineEmits<{
  (event: "updateValidState", isValid: boolean): void;
}>();

const commentsValid = ref(false);

const results = defineModel<SupplierSurveyResults>("modelValue", {
  default: () => ({rating: null, comments: null}),
});

const formIsValid = computed<boolean>(() => {
  return commentsValid.value !== false; // field is optional, so `commentsValid` can be undefined if it's blank
});

watch(formIsValid, (isValid) => {
  emit("updateValidState", isValid);
});

const sanitizedBody = computed(() => {
  return sanitizeTextForHtml(props.bodyText);
});
</script>

<style lang="less" scoped>
@import "@/less/global.less";

.supplier-survey-header {
  background: @purple200;
  padding: 0 1rem 1rem;
}

.header-icon {
  font-size: 72px;
  color: @purple700;
  text-align: center;
}

.header-text {
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;
}

.supplier-survey-body {
  background: white;
  padding: 1rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
}

.body-text {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1.5px solid @grey300;
}
</style>
