import sanitizeHtml from "sanitize-html";

const MAX_BASE64_SIZE = 500000;

function isValidBase64Image(src: string): boolean {
  if (src.startsWith("data:image/")) {
    if (src.startsWith("data:image/svg+xml")) {
      return false;
    }
    const base64Data = src.split(",")[1] || "";
    return base64Data.length <= MAX_BASE64_SIZE;
  }
  return false;
}

function isValidHttpImage(src: string): boolean {
  try {
    const url = new URL(src);
    return url.protocol === "https:";
  } catch {
    return false;
  }
}

export function sanitizeTextForHtml(dirty: string): string {
  // Allowed images and added a few xss/ddos checks
  return sanitizeHtml(dirty, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
    allowedAttributes: {img: ["src", "alt"]},
    allowedSchemes: ["https", "data"],
    allowedSchemesByTag: {img: ["https", "data"]},
    allowedSchemesAppliedToAttributes: ["src"],
    transformTags: {
      img: (tagName, attribs) => {
        const {src} = attribs;
        if (isValidHttpImage(src)) {
          return {tagName, attribs};
        }
        if (isValidBase64Image(src)) {
          return {tagName, attribs};
        }
        return {tagName: "img", attribs: {src: ""}};
      },
    },
    disallowedTagsMode: "discard",
    exclusiveFilter: (frame) => {
      return Object.keys(frame.attribs).some((attr) => attr.startsWith("on"));
    },
  });
}
