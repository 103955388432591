<template>
  <GraphiteButton @click="showPopup = true" :attrs="{...$attrs}" v-bind="props">
    <template #icon v-if="$slots.icon">
      <slot name="icon" />
    </template>
    <template #default v-if="$slots.default">
      <slot />
    </template>
  </GraphiteButton>

  <GraphiteModal
    :title="modalTitle"
    :okTitle="modalOkText"
    :cancelTitle="modalCancelText"
    :okVariant="modalOkVariant"
    :cancelVariant="modalCancelVariant"
    @ok="confirm()"
    @cancel="reject()"
    v-model:visible="showPopup"
  >
    <template #default>
      <slot name="modalContent">
        {{ modalContent }}
      </slot>
    </template>
  </GraphiteModal>
</template>

<script setup lang="ts">
import GraphiteButton from "@/components/common/GraphiteButton.vue";
import type {ConfirmButtonProps} from "@/utils/graphite-button-types";
import {translate} from "@/composables/i18n";
import {ref} from "vue";

const props = withDefaults(defineProps<ConfirmButtonProps>(), {
  modalTitle: translate("general.confirmation"),
  modalContent: translate("general.generic_confirmation_message"),
  modalOkText: translate("actions.confirm"),
  modalCancelText: translate("actions.cancel"),
});

const emit = defineEmits<{
  (event: "confirm"): void;
  (event: "reject"): void;
}>();

function confirm() {
  emit("confirm");
  showPopup.value = false;
}

function reject() {
  emit("reject");
  showPopup.value = false;
}

const showPopup = ref(false);
</script>
