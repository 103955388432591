export enum EntityAccessStatus {
  REQUESTED = "requested",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  INVITED = "invited",
  DELETED = "deleted", // Should never appear in an EntityAssociation (it should simply be deleted), but useful for log events
  INACTIVE = "inactive", // Should never appear in an EntityAssociation. See UserService.getUsersForEntity()
  NOBODY = "nobody", // hidden from user management
}

export enum RoleType {
  TOPIC_OWNER = "topic_owner",
  SECURITY_ROLE = "security_role",
  USER_GROUP = "user_group",
}

export enum RoleTypeName {
  TOPIC_OWNER = "Topic Owner",
}

export enum PhoneNumberType {
  MOBILE = "mobile",
  WORK = "work",
  HOME = "home",
  TWO_FACTOR_AUTHENTICATION = "two_factor_authentication",
  FAX = "fax",
}

export enum SecondaryEmailType {
  PERSONAL = "personal",
}

export enum TwoFactorMethod {
  PHONE = "phone",
  EMAIL = "email",
  OTP = "otp",
}

export enum ValidationOptionType {
  PHONE = "phone",
  EMAIL = "email",
}

export enum SpecialUser {
  AUTO_APPROVED = "5d0c000302feae634cd856ed",
  SUPPORT_USER = "5d41ac3575172c0775a28505",
}

export enum UserChangeReason {
  PASSWORD = "password",
  EMAIL = "email",
  SECONDARY_EMAIL = "secondary email",
  PHONE_NUMBERS = "phone number",
  TWO_FACTOR_METHOD = "two factor authentication method",
  RESET_TWO_FACTOR = " two factor authentication reset",
}

export enum UserColumnOptionType {
  SEARCH_COLUMNS = "searchColumns",
  TASK_COLUMNS = "taskColumns",
}

export const SELECT_DEFAULT_NUM_USERS = 20;
