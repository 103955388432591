import type {MagicConditions} from "pg-isomorphic/api/search";
import {magicAnswerKeyMap} from "pg-isomorphic/api/search";
import type {SearchCondition} from "pg-isomorphic/api/searchcondition";
import {SearchOperator} from "pg-isomorphic/api/searchcondition";
import {SpecialSearchFields} from "pg-isomorphic/enums/search";
import {allParents} from "pg-isomorphic/profile";
import type {JSONQuestion} from "pg-isomorphic/utils";
import type {Dictionary} from "ramda";

export function firstOrValue<T>(v: T[] | T): T | undefined {
  if (Array.isArray(v)) {
    if (v.length > 0) {
      return v[0];
    }
  } else {
    return v;
  }
  return undefined;
}

// Mutates conditions
export function extractAndRemoveMagicConditions(conditions: SearchCondition): MagicConditions {
  const magic: Dictionary<string> = {};
  const visitor = (c: SearchCondition) => {
    if (c.answerKey === SpecialSearchFields.ConnectionRole && c.values?.length > 1) {
      c.operator = SearchOperator.NONE;
    } else if (c.answerKey && c.answerKey in magicAnswerKeyMap) {
      magic[magicAnswerKeyMap[c.answerKey]] = firstOrValue<any>(c.values);
      c.operator = SearchOperator.NONE;
    }
    if (c.children) {
      c.children.forEach(visitor);
    }
  };
  visitor(conditions);
  return magic;
}

export function hasAnyAnswerKey(conditions: SearchCondition, answerKeys: string[]): boolean {
  let hasKey = false;
  if (!answerKeys.length) {
    return hasKey;
  }
  const visitor = (c: SearchCondition) => {
    if (!c) {
      return;
    }
    if (answerKeys.indexOf(c.answerKey) > -1) {
      hasKey = true;
    }
    if (c.children) {
      c.children.forEach(visitor);
    }
  };
  visitor(conditions);
  return hasKey;
}

export function questionLabelBreadcrumbs(question: JSONQuestion): string[] {
  const parents = allParents(question);
  let labels: string[] = parents.map((p) => p.label!);
  labels.pop(); // remove ROOT
  if (labels.length > 1) {
    labels.shift(); // remove myself
  }
  labels = labels.slice(0, 2); // take first 2
  labels.reverse();
  return labels;
}
