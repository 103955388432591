import type {SupplierSurveyOptions} from "@/composables/supplierSurvey";
import type {UpdateTopicOwnerEvent} from "@/composables/topicAssign";
import type {Emitter} from "mitt";
import mitt from "mitt";
import type {ToastMessageOptions} from "primevue/toast";
import {onBeforeUnmount, onMounted} from "vue";

// @ts-ignore -- doesn't like that we are trying to strongly type it!
export const globalEmitter = mitt<GlobalEvents>();

/** @deprecated use `globalEmitter` */
export interface FallbackLegacyEmitter {
  $on(event: string | string[], fn: (data: any) => void): void;
  $once(event: string | string[], fn: (data: any) => void): void;
  $off(event: string | string[], fn: (data: any) => void): void;
  $emit(event: string, data: any): void;
}

// for backward compatibility, don't use
export default (store) => {
  const emitter: Emitter<any> = globalEmitter;

  store.emitter = {
    /** @deprecated use `globalEmitter` */
    $on: (types, fn) => {
      types = Array.isArray(types) ? types : [types];
      types.forEach((type) => {
        emitter.on.bind(emitter)(type, fn);
      });
    },
    /** @deprecated use `globalEmitter` */
    $once: (types, fn) => {
      types = Array.isArray(types) ? types : [types];
      types.forEach((type) => {
        emitter.on.bind(emitter)(type, oneTimeFn);

        function oneTimeFn(data: any) {
          fn(data);
          emitter.off(type, oneTimeFn);
        }
      });
    },
    /** @deprecated use `globalEmitter` */
    $off: (types, fn) => {
      types = Array.isArray(types) ? types : [types];
      types.forEach((type) => {
        emitter.off.bind(emitter)(type, fn);
      });
    },
    /** @deprecated use `globalEmitter` */
    $emit: emitter.emit.bind(emitter),
  } as FallbackLegacyEmitter;
};

export enum GlobalEvent {
  Toast = "Toast",
  CloseEllipsisWithActions = "CloseEllipsisWithActions",
  OpenSupplierSurvey = "OpenSupplierSurvey",
  UpdateTopicOwner = "UpdateTopicOwner",
}

interface GlobalEvents {
  [GlobalEvent.Toast]: ToastMessageOptions;
  [GlobalEvent.CloseEllipsisWithActions]: string; // close all except those that match this ID
  [GlobalEvent.OpenSupplierSurvey]: SupplierSurveyOptions;
  [GlobalEvent.UpdateTopicOwner]: UpdateTopicOwnerEvent;
}

export function useEventListener<T extends Record<string, any> = GlobalEvents, K extends keyof T = keyof GlobalEvents>(
  event: K,
  callback: (data: T[K]) => void,
  emitter: Emitter<T> = globalEmitter as unknown as Emitter<T>,
) {
  onMounted(() => {
    emitter.on(event, callback);
  });
  onBeforeUnmount(() => {
    emitter.off(event, callback);
  });
}
