import {MenuType} from "@/composables/menu/enums";
import {allLoaderPermissions, Permission} from "pg-isomorphic/permissions";

export default {
  component: () => import("@/components/layouts/AdminWrap.vue"),
  path: "/admin",
  no_role_modal: true,
  children: [
    {
      path: "demo",
      component: () => import("@/components/admin/DemoData.vue"),
      meta: {
        title: "nav.demo_data",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.DEMO_DATA],
      },
    },
    {
      path: "expressions",
      component: () => import("@/components/admin/Expressions.vue"),
      meta: {
        title: "nav.expressions",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.DEMO_DATA],
      },
    },
    {
      path: "questions",
      component: () => import("@/components/admin/Loader/Questions.vue"),
      meta: {
        title: "nav.questions",
        menu_type: MenuType.Admin,
        admin_perms: allLoaderPermissions,
      },
    },
    {
      path: "import/:jobId?",
      component: () => import("@/components/admin/import/BulkImports.vue"),
      meta: {
        title: "nav.import",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.DEMO_DATA],
      },
    },
    {
      path: "eventlog/:eventLogId?",
      component: () => import(/* webpackChunkName: "pages-admin" */ "../components/datarefs/EventLogViewer.vue"),
      meta: {
        title: "nav.audit_log",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.VIEW_ENTITY_DATA],
      },
    },
    {
      path: "batchJobs",
      component: () => import(/* webpackChunkName: "pages-admin" */ "../components/datarefs/BatchJobViewer.vue"),
      meta: {
        admin_perms: [Permission.VIEW_ENTITY_DATA],
      },
    },
    {
      path: "about",
      component: () => import("@/components/admin/About.vue"),
      meta: {
        title: "nav.about",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.CHECK_VERSION],
      },
    },
    {
      path: "",
      component: () => import("@/components/admin/UsersAdmin/OuterAdminWrap.vue"),
      meta: {
        title: "nav.user_admin",
        menu_type: MenuType.Admin,
      },
      children: [
        {
          path: "users",
          component: () => import("@/components/admin/UsersAdmin/ManageUsers.vue"),
          meta: {
            title: "admin.users.title",
            menu_type: MenuType.Admin,
            admin_perms: [Permission.UPDATE_USER, Permission.DELETE_USER, Permission.APPROVE_USER],
          },
        },
        {
          path: "badges",
          component: () => import("@/components/admin/UsersAdmin/ManageBadges.vue"),
          meta: {
            title: "admin.badges.title",
            menu_type: MenuType.Admin,
          },
        },
        {
          path: "manage-connections/:connectionId?",
          component: () => import("@/components/admin/UsersAdmin/ManageConnections.vue"),
          meta: {
            title: "nav.connections",
            menu_type: MenuType.Admin,
            admin_perms: [Permission.MANAGE_ENTITY_CONNECTIONS],
          },
        },
        {
          path: "user-groups",
          component: () => import("@/components/admin/UsersAdmin/ManageUserGroups.vue"),
          meta: {
            title: "admin.groups.title",
            menu_type: MenuType.Admin,
            admin_perms: [Permission.MANAGE_USER_GROUPS],
          },
        },
        {
          path: "manage-questions",
          component: () => import("@/components/admin/QuestionAdmin/ManageQuestions.vue"),
          meta: {
            title: "admin.questions.title",
            menu_type: MenuType.Admin,
            // TODO I assume we want a permission for this...?
            // admin_perms: [],
            fullPageLayout: true,
          },
        },
        {
          path: "workflows",
          component: () => import("@/components/admin/workflow/ManageWorkflows.vue"),
          meta: {
            title: "admin.workflow.title",
            menu_type: MenuType.Admin,
            admin_perms: [Permission.MANAGE_WORKFLOWS],
            fullPageLayout: true,
          },
        },
        {
          path: "workflows/:workflowId",
          component: () => import("@/components/admin/workflow/ManageWorkflow.vue"),
          props: true,
          meta: {
            title: "admin.workflow.title",
            menu_type: MenuType.Admin,
            admin_perms: [Permission.MANAGE_WORKFLOWS],
            fullPageLayout: true,
          },
        },
        {
          path: "third-party",
          component: () => import("@/components/admin/ThirdPartyAdmin/ThirdPartyList.vue"),
          meta: {
            title: "nav.third_party_integrations",
            menu_type: MenuType.Admin,
            admin_perms: [Permission.MANAGE_INTEGRATIONS],
            fullPageLayout: true,
          },
        },
        {
          path: "third-party/:feature",
          component: () => import("@/components/admin/ThirdPartyAdmin/ThirdPartyDetail.vue"),
          props: true,
          meta: {
            title: "nav.third_party_integrations",
            menu_type: MenuType.Admin,
            admin_perms: [Permission.MANAGE_INTEGRATIONS],
            fullPageLayout: true,
          },
        },
        {
          path: "third-party/:feature/callback",
          component: () => import("@/components/admin/ThirdPartyAdmin/ThirdPartyCallback.vue"),
          meta: {
            title: "nav.third_party_integrations",
            menu_type: MenuType.Admin,
            admin_perms: [Permission.MANAGE_INTEGRATIONS],
            fullPageLayout: true,
          },
        },
        {
          path: "lists",
          component: () => import("@/components/admin/ListsAdmin/AdminLists.vue"),
          meta: {
            title: "nav.lists",
            menu_type: MenuType.Admin,
            admin_perms: [Permission.VIEW_ANY_ENTITY_DATA],
          },
        },
        {
          path: "lists/:idOrKey",
          component: () => import("@/components/admin/ListsAdmin/AdminListEdit.vue"),
          props: (route) => ({
            ...route.params,
          }),
          meta: {
            title: "nav.manage_list",
            menu_type: MenuType.Admin,
            admin_perms: [Permission.MANAGE_OWN_VALUE_SETS],
            fullPageLayout: true,
          },
        },
        {
          path: "lists-admin",
          component: () => import("@/components/admin/ListsAdmin/AdminListAdmin.vue"),
          props: (route) => ({
            ...route.params,
          }),
          meta: {
            title: "nav.manage_list",
            menu_type: MenuType.Admin,
            // this is the most restrictive permission
            admin_perms: [Permission.DELETE_GRAPHITE_VALUE_SETS],
            fullPageLayout: true,
          },
        },
        {
          path: "cards",
          component: () => import("@/components/admin/CardsAdmin/ManageCards.vue"),
          meta: {
            title: "admin.questions.title",
            menu_type: MenuType.Admin,
            admin_perms: [Permission.VIEW_ANY_ENTITY_DATA],
            fullPageLayout: true,
          },
        },
        {
          path: "cards/:cardId",
          component: () => import("@/components/admin/CardsAdmin/CardDetail.vue"),
          meta: {
            title: "admin.questions.title",
            menu_type: MenuType.Admin,
            admin_perms: [Permission.VIEW_ANY_ENTITY_DATA],
            fullPageLayout: true,
          },
        },
      ],
    },
    {
      path: "entityreports",
      component: () => import("@/components/admin/EntityReports.vue"),
      meta: {
        title: "nav.reports",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.ADMIN_REPORTS],
      },
    },
    {
      path: "interfaces/:tab?",
      component: () => import("@/components/admin/interfaces/Interfaces.vue"),
      meta: {
        title: "nav.interfaces",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.ADMIN_INTERFACES, Permission.CONFIGURE_ANY_INTEGRATION],
      },
    },
    {
      path: "company",
      component: () => import("@/components/admin/entity/EntityAdmin.vue"),
      meta: {
        title: "nav.entity_admin",
        menu_type: MenuType.Admin,
        admin_perms: [
          Permission.MANAGE_ENTITY_GENERAL_SETTINGS,
          Permission.MANAGE_COMPANY,
          Permission.VIEW_MANAGE_COMPANY,
        ],
      },
    },
    {
      path: "search_index",
      component: () => import("@/components/admin/ManageSearchIndex.vue"),
      meta: {
        title: "nav.search_index",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.MANAGE_SEARCH_INDEX],
      },
    },
    {
      path: "entities/:tab?",
      component: () => import("@/components/admin/ManageEntities/Entities.vue"),
      meta: {
        title: "nav.entities_admin",
        menu_type: MenuType.Admin,
        admin_perms: [
          Permission.VIEW_ANY_ENTITY_DATA,
          Permission.ADD_USER_TO_ANY_ENTITY,
          Permission.MANAGE_SEARCH_INDEX,
        ],
      },
    },
    {
      path: "allusers",
      component: () => import("@/components/admin/ManageUsers.vue"),
      meta: {
        title: "nav.users_admin",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.VIEW_ANY_ENTITY_DATA, Permission.ADD_USER_TO_ANY_ENTITY],
      },
    },
    {
      path: "allusers/:userId",
      component: () => import("@/components/admin/ManageUserPref.vue"),
      meta: {
        title: "nav.users_admin_pref",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.VIEW_ANY_ENTITY_DATA, Permission.ADD_USER_TO_ANY_ENTITY],
      },
    },
    {
      path: "supplier-surveys",
      component: () => import("@/components/admin/SupplierSurveys/SupplierSurveysReport.vue"),
      meta: {
        title: "nav.supplier_surveys_report",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.VIEW_NPS_REPORTS],
      },
    },
    {
      path: "proveuser",
      component: () => import("@/components/admin/ManageProveUsers.vue"),
      meta: {
        title: "nav.prove_verifications",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.VIEW_ANY_ENTITY_DATA],
      },
    },
    {
      path: "proveuser/:proveScoreId",
      component: () => import("@/components/admin/ProveUserDetail.vue"),
      meta: {
        title: "nav.prove_verifications",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.VIEW_ANY_ENTITY_DATA],
      },
    },
    {
      path: "veriffuser",
      component: () => import("@/components/admin/ManageVeriffUsers.vue"),
      meta: {
        title: "nav.veriff_verifications",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.VIEW_ANY_ENTITY_DATA],
      },
    },
    {
      path: "veriffuser/:veriffScoreId",
      component: () => import("@/components/admin/VeriffUserDetail.vue"),
      meta: {
        title: "nav.veriff_verifications",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.VIEW_ANY_ENTITY_DATA],
      },
    },
    {
      path: "redirects",
      component: () => import("@/components/admin/ManageRedirects.vue"),
      meta: {
        title: "nav.manage_redirects",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.VIEW_ANY_ENTITY_DATA],
      },
    },
    {
      path: "switched",
      component: () => import("@/components/admin/ManageSwitched.vue"),
      meta: {
        title: "nav.manage_switched",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.VIEW_ANY_ENTITY_DATA],
      },
    },
    {
      path: "validations",
      component: () => import("@/components/admin/Validations.vue"),
      meta: {
        title: "nav.validation_field",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.MANAGE_ANY_FIELD_VALIDATION],
      },
    },
    {
      path: "bankapprovals",
      component: () => import("@/components/admin/BankApprovalTasks.vue"),
      meta: {
        title: "nav.bank_approvals",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.VIEW_ANY_ENTITY_DATA],
      },
    },
    {
      path: "reports",
      component: () => import("@/components/admin/Reports.vue"),
      meta: {
        title: "nav.data_reports",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.VIEW_ANY_ENTITY_DATA],
      },
    },
    {
      path: "bank_routings",
      component: () => import("@/components/admin/ManageBankRouting.vue"),
      meta: {
        title: "nav.bank_routings",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.VIEW_ANY_ENTITY_DATA],
      },
    },
    {
      path: "profiling",
      component: () => import("@/components/admin/Profiling.vue"),
      meta: {
        title: "profiling_field",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.VIEW_ANY_ENTITY_DATA],
      },
    },
    {
      path: `skeleton_reminders`,
      component: () => import("@/components/admin/ManageSkeletonReminderEmails.vue"),
      meta: {
        title: "nav.skeleton_reminder",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.VIEW_ANY_ENTITY_DATA],
      },
    },
    {
      path: `localization`,
      component: () => import("@/components/admin/Localization.vue"),
      meta: {
        title: "nav.localization",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.MANAGE_LOCALIZATION],
      },
    },
    {
      path: `netsuite`,
      component: () => import("@/components/admin/NetSuite.vue"),
      meta: {
        title: "nav.netsuite",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.MANAGE_NETSUITE_INTEGRATION],
      },
    },
    {
      path: `intacct`,
      component: () => import("@/components/admin/Intacct.vue"),
      meta: {
        title: "nav.intacct",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.MANAGE_INTACCT_INTEGRATION],
      },
    },
    {
      path: `zendesk`,
      component: () => import("@/components/admin/Zendesk.vue"),
      meta: {
        title: "nav.zendesk",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.MANAGE_ZENDESK_INTEGRATION],
      },
    },
    {
      path: `rawexports`,
      component: () => import("@/components/admin/RawExports.vue"),
      meta: {
        title: "nav.rawexports",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.VIEW_ANY_ENTITY_DATA],
      },
    },
    {
      path: `featureusage`,
      component: () => import("@/components/admin/FeatureUsage.vue"),
      meta: {
        title: "nav.feature_usage",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.VIEW_ENTITY_FEATURE_USAGE, Permission.VIEW_ANY_ENTITY_FEATURE_USAGE],
      },
    },
    {
      path: `importusers`,
      component: () => import("@/components/admin/entity/BulkUserImport.vue"),
      meta: {
        title: "nav.bulk_user_import",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.BULK_INVITE],
      },
    },
    {
      path: `bulktasks`,
      component: () => import("@/components/admin/entity/BulkTaskProcessing.vue"),
      meta: {
        title: "nav.bulk_task_processing",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.BULK_INVITE],
      },
    },
    {
      path: "ai-packages",
      component: () => import("@/components/admin/AiPackagesAdmin/ManageAiPackages.vue"),
      meta: {
        title: "nav.ai_packages",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.VIEW_ANY_ENTITY_DATA],
      },
    },
    {
      path: "ai-packages/:packageId",
      component: () => import("@/components/admin/AiPackagesAdmin/EditAiPackage.vue"),
      props: (route) => ({...route.params}),
      meta: {
        title: "nav.ai_packages",
        menu_type: MenuType.Admin,
        admin_perms: [Permission.VIEW_ANY_ENTITY_DATA],
      },
    },
  ],
};
