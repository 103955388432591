export enum Severity {
  WARNING = "warning",
  ERROR = "error",
}

export enum LoaderErrorCode {
  GENERAL_ERROR = "E0000",
  GRAPHITE_OWNED_WATCHING_NOT_MINE = "E0001",

  REQUIREDNESS_BASED_ON_CURRENT_USER = "W0001",
  UNINLINED_CALCULATION = "W0002",
}

export const loaderErrorMessages: {[key in LoaderErrorCode]: string} = {
  [LoaderErrorCode.GENERAL_ERROR]: "General error",
  [LoaderErrorCode.GRAPHITE_OWNED_WATCHING_NOT_MINE]:
    'Graphite-owned trigger may not watch answers changed events except on profile "Mine".',
  [LoaderErrorCode.REQUIREDNESS_BASED_ON_CURRENT_USER]:
    'Question has a requiredness or visibility expression that depends on the current user. You should expect "missing" required question tasks.',
  [LoaderErrorCode.UNINLINED_CALCULATION]:
    "An expression uses a calculation that cannot be inlined, which may cause unexpected behavior.",
};

export enum LoaderStatus {
  UPLOADING = "uploading",
  DOWNLOADING_FROM_GCS = "downloading_gcs",
  READING_XLSX = "reading_xlsx",

  PARSING_GROUPS = "parsing_groups",
  PARSING_QUESTIONS = "parsing_questions",
  PARSING_OVERRIDES = "parsing_overrides",
  PARSING_TOPICS = "parsing_topics",
  PARSING_SEARCH = "parsing_search",
  PARSING_RISK = "parsing_risk",
  PARSING_CAMPAIGN_COMPANY_COLUMNS = "parsing_campaign_company_columns",
  PARSING_TABLES = "parsing_tables",
  PARSING_VALUE_SETS = "parsing_value_sets",
  PARSING_TRIGGERS = "parsing_triggers",
  PARSING_ELEMENT_ACTIONS = "parsing_element_actions",
  PARSING_VISUAL_WORKFLOWS = "parsing_visual_workflows",
  PARSING_CONNECTIONS = "parsing_connections",
  PARSING_NETSUITE_MAPPING = "parsing_netsuite_mapping",
  PARSING_NETSUITE_LOOKUPS = "parsing_netsuite_lookups",
  PARSING_NETSUITE_SETTINGS = "parsing_netsuite_settings",
  PARSING_INTACCT_MAPPING = "parsing_intacct_mapping",
  PARSING_INTACCT_LOOKUPS = "parsing_intacct_lookups",
  PARSING_INTACCT_SETTINGS = "parsing_intacct_settings",
  PARSING_TRANSFORMATION_MAPPING = "parsing_transformation_mapping",
  PARSING_TRANSLATION_TABLES = "parsing_translation_tables",
  PARSING_TRANSFORMATION = "parsing_transformation",
  PARSING_CAMPAIGNS = "parsing_campaigns",
  PARSING_KIT_QUESTIONS = "parsing_kit_questions",
  PARSING_KIT_COLUMNS = "parsing_kit_columns",
  PARSING_KITS = "parsing_kits",
  PARSING_EXTERNAL_INTERFACES = "parsing_interface_transformations",
  PARSING_CARDS = "parsing_cards",
  PARSING_CLIENT_TRIGGERS = "parsing_client_triggers",
  PARSING_BATCH_JOB = "parsing_batch_job",
  PARSING_SECRETS = "parsing_secrets",
  PARSING_MASTER_DATA_TYPES = "parsing_master_data_types",

  LOADING_GROUPS = "loading_groups",
  LOADING_QUESTIONS = "loading_questions",
  LOADING_OVERRIDES = "loading_overrides",
  LOADING_CUSTOMER_OVERRIDES = "loading_customer_overrides",
  LOADING_TOPICS = "loading_topics",
  LOADING_SEARCH = "loading_search",
  LOADING_RISK = "loading_risk",
  LOADING_CAMPAIGN_COMPANY_COLUMNS = "loading_campaign_company_columns",
  LOADING_TABLES = "loading_tables",
  LOADING_VALUE_SETS = "loading_value_sets",
  LOADING_TRIGGERS = "loading_triggers",
  LOADING_ELEMENT_ACTIONS = "loading_element_actions",
  LOADING_VISUAL_WORKFLOWS = "loading_visual_workflows",
  LOADING_CONNECTIONS = "loading_connections",
  LOADING_NETSUITE_MAPPING = "loading_netsuite_mapping",
  LOADING_NETSUITE_LOOKUPS = "loading_netsuite_lookups",
  LOADING_NETSUITE_SETTINGS = "loading_netsuite_settings",
  LOADING_INTACCT_MAPPING = "loading_intacct_mapping",
  LOADING_INTACCT_LOOKUPS = "loading_intacct_lookups",
  LOADING_INTACCT_SETTINGS = "loading_intacct_settings",
  LOADING_TRANSFORMATION_MAPPING = "loading_transformation_mapping",
  LOADING_TRANSLATION_TABLES = "loading_translation_tables",
  LOADING_TRANSFORMATION = "loading_transformation",
  LOADING_CAMPAIGNS = "loading_campaigns",
  LOADING_KIT_QUESTIONS = "loading_kit_questions",
  LOADING_KIT_COLUMNS = "loading_kit_columns",
  LOADING_KITS = "loading_kits",
  LOADING_EXTERNAL_INTERFACES = "loading_interface_transformations",
  LOADING_CARDS = "loading_cards",
  LOADING_CLIENT_TRIGGERS = "loading_client_triggers",
  LOADING_BATCH_JOB = "loading_batch_job",
  LOADING_SECRETS = "loading_secrets",
  LOADING_MASTER_DATA_TYPES = "loading_master_data_types",

  UPDATING_TASK_TOPICS = "updating_task_topics",

  LOADING_COMPLETE = "loading_complete",
  LOADING_FAILED = "loading_failed",
}
